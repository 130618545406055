import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import $ from "jquery";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";

export default function FollowUp() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [visible, setVisible] = useState(false);
    const [updateId, setUpdateId] = useState('');
//    Form Fields
    const [testTaker, setTestTaker] = useState('');
    const [cnic, setCnic] = useState('');
    const [dob, setDob] = useState([]);
    const [testDate, setTestDate] = useState([]);
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState("");
    const [examType, setExamType] = useState("");
    const [testType, setTestType] = useState("");
    const [testBody, setTestBody] = useState("");
    const [total, setTotal] = useState("");
    const [registrationDate, setRegistrationDate] = useState("");
    const [result, setResult] = useState("");
    const [recommendation, setRecommendation] = useState("");
    const [trainer, setTrainer] = useState("");
    const [invigilator, setInvigilator] = useState("");
    const [slipNo, setSlipNo] = useState("");
    const [batch, setBatch] = useState("");
    const [branch, setBranch] = useState("");
    useEffect(() => {
        LoadData();
    }, [])


    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadCrashCourses");
        formdata.append("branch", localStorage.getItem("Branch"));
        formdata.append("role", localStorage.getItem("Role"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterData = (e) => {
        let val = e.target.value.toLowerCase();
        if (val) {
            const filter = filterRecord.filter((d) => {
                return d.CNIC.toLowerCase().indexOf(val) > -1 || d.ContactNo.toLowerCase().indexOf(val) > -1 || d.Email.toLowerCase().indexOf(val) > -1
            })
            console.log(filter);
            setCustomerData(filter);
        } else {
            setCustomerData(filterRecord);
        }
    }

    function filterDateRecord() {
        const filter = filterRecord.filter((d) => {
            return d.Date >= startDate && d.Date <= endDate
        })
        console.log(filter);
        setCustomerData(filter);
    }
    const Del=()=>{
        const acceptDelete = async () => {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
            var formdata = new FormData();
            formdata.append("page", "DeleteCrashCourse");
            formdata.append("id", updateId);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${AppLink}Leads.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    setLoading(false);
                    if (result.status == "1") {
                        // let Data = result.Data;
                        LoadData();
                        showSuccess("Record Deleted Successfully");
                    } else {
                        showError();
                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                    }
                })
                .catch(error => console.log('error', error));
        }
        if (localStorage.getItem("Role") !== "Operator" && localStorage.getItem("Role") !== "Counselor") {
            return (
                <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                    confirmPopup({
                        target: event.currentTarget,
                        message: 'Do you want to delete this record?',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept() {
                            acceptDelete()
                        },
                        reject() {
                            console.log("Reject");
                        }
                    });


                }}/>
            )
        }
        else
        {
            return (
                <></>
            )
        }
    }

    const Edit=()=>{
        return (
            <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                history.push("/add-crash?id=" + updateId);
            }}/>
        )
    }

    return (
        <section className="content">
            <Card header={() => {
                return (
                    <div className="row justify-content-end gap-4 mt-4">
                        <Button onClick={() => history.push('/add-crash')} label="Add Student"
                                icon="pi pi-plus"
                                style={{width: "auto"}}/>
                    </div>
                )
            }
            }
                  title={() => {
                      return (
                          <>
                              <button onClick={() => history.go(-1)}
                                      style={{border: "none", outline: "none", background: "transparent"}}><i
                                  className={'fas fa-arrow-left'}></i></button>
                              &nbsp;&nbsp;&nbsp;
                              <span>Crash Course Students</span>
                              <div className="row justify-content-between">
                                  <div className="col-md-3 mt-1">
                                      <div className="form-group">
                                          <InputText type={'search'} onChange={(e) => filterData(e)}
                                                     placeholder={'Keyword Search...'}/>
                                      </div>
                                  </div>
                                  <div className="col-md-7 mt-1">
                                      <div className="row">
                                          <div className="col-md-4 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'}
                                                             onChange={(e) => setStartDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-4 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'}
                                                             onChange={(e) => setEndDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-3 mt-1">
                                              <div className="form-group">
                                                  <button onClick={filterDateRecord}
                                                          className={'btn btn-primary form-control'}>Search
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </>
                      )
                  }}
                  className="card">
                <Toast ref={toast}/>
                <Dialog header="Student Detail" visible={visible} style={{width: '50vw'}}
                        onHide={() => setVisible(false)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Test Taker <code>*</code></label>
                                <InputText value={testTaker} disabled={true}
                                           onChange={(e) => setTestTaker(e.target.value)} name='taker'
                                           placeholder={'.....'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>CNIC / Passport <code>*</code></label>
                                <InputText value={cnic} type={'number'} disabled={true}
                                           onChange={(e) => setCnic(e.target.value)} name='cnic'
                                           placeholder={'.....'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Date of Birth <code>*</code></label>
                                <InputText value={dob} type={'date'} disabled={true}
                                           onChange={(e) => setDob(e.target.value)} name='dob'
                                           placeholder={'.....'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Test Date <code>*</code></label>
                                <InputText value={testDate} type={'date'} disabled={true}
                                           onChange={(e) => setTestDate(e.target.value)} name='testDate'
                                           placeholder={'.....'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Contact No <code>*</code></label>
                                <InputText value={contact} type={'number'} disabled={true}
                                           onChange={(e) => setContact(e.target.value)} name='contact'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Email Address <code>*</code></label>
                                <InputText value={email} disabled={true}
                                           onChange={(e) => setEmail(e.target.value)} name='email'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Exam Type <code>*</code></label>
                                <Dropdown name={'examType'} value={examType} disabled={true} onChange={(e) => setExamType(e.value)}
                                          options={[
                                              {name: "CD", value: "CD"},
                                              {name: "Paper Type", value: "Paper Type"},
                                          ]} optionLabel="name"
                                          placeholder="Select Exam Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Test Type <code>*</code></label>
                                <InputText value={testType} disabled={true}
                                           onChange={(e) => setTestType(e.target.value)} name='testType'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Test Body <code>*</code></label>
                                <InputText value={testBody} disabled={true}
                                           onChange={(e) => setTestBody(e.target.value)} name='body'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Total Test Attempted <code>*</code></label>
                                <InputText value={total} type={'number'} disabled={true}
                                           onChange={(e) => setTotal(e.target.value)} name='total'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Recommendation <code>*</code></label>
                                <InputText value={recommendation} disabled={true}
                                           onChange={(e) => setRecommendation(e.target.value)} name='recommendation'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Trainer <code>*</code></label>
                                <InputText value={trainer} disabled={true}
                                           onChange={(e) => setTrainer(e.target.value)} name='trainer'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Invigilator <code>*</code></label>
                                <InputText value={invigilator} disabled={true}
                                           onChange={(e) => setInvigilator(e.target.value)} name='invigilator'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Payment/Slip No<code>*</code></label>
                                <InputText value={slipNo} disabled={true}
                                           onChange={(e) => setSlipNo(e.target.value)} name='slip'
                                           placeholder={'.....'}/>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Batch <code>*</code></label>
                                <Dropdown name={'batch'} disabled={true} value={batch} onChange={(e) => setBatch(e.value)}
                                          options={[
                                              {name: "9-1", value: "9-1"},
                                              {name: "4-8", value: "4-8"},
                                          ]} optionLabel="name"
                                          placeholder="Select Batch" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Branch <code>*</code></label>
                                <Dropdown name={'branch'} value={branch} disabled={true} onChange={(e) => setBranch(e.value)} options={[
                                    {
                                        name: "Multan",
                                        value: "Multan"
                                    },
                                    {
                                        name: "Gujranwala",
                                        value: "Gujranwala"
                                    },
                                    {
                                        name: "Sahiwal",
                                        value: "Sahiwal"
                                    },
                                    {
                                        name: "Bahawalpur",
                                        value: "Bahawalpur"
                                    }
                                ]} optionLabel="name"
                                          placeholder="Select a Branch" className='form-control'/>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <Edit/>&nbsp;&nbsp;&nbsp;
                        <Del/>&nbsp;&nbsp;&nbsp;
                    </div>
                </Dialog>
                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No Student Found !'} paginator
                           rows={10}
                           onRowClick={async (p) => {
                               let id = customerData[p.index].id;
                               setUpdateId(id);
                               setTestTaker(customerData[p.index].Taker)
                               setCnic(customerData[p.index].CNIC)
                               setTestDate(customerData[p.index].TestDate)
                               setDob(customerData[p.index].DOB)
                               setContact(customerData[p.index].ContactNo)
                               setEmail(customerData[p.index].Email)
                               setExamType(customerData[p.index].ExamType)
                               setTestType(customerData[p.index].TestType)
                               setTestBody(customerData[p.index].TestBody)
                               setTotal(customerData[p.index].Total)
                               setRecommendation(customerData[p.index].Recommendation)
                               setTrainer(customerData[p.index].Trainer)
                               setInvigilator(customerData[p.index].Invigilator)
                               setBranch(customerData[p.index].Branch)
                               setBatch(customerData[p.index].Batch)
                               setSlipNo(customerData[p.index].SlipNo)
                               setVisible(true);
                           }}
                           rowClassName={'cursor-pointer'}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}

                >
                    <Column field="Taker" header="Test Taker" style={{minWidth: '10rem'}}/>
                    <Column field="CNIC" header="CNIC/Password" style={{minWidth: '10rem'}}/>
                    <Column field="ContactNo" header="Contact No" style={{minWidth: '10rem'}}/>
                    <Column field="Email" header="Email" style={{minWidth: '10rem'}}/>
                    <Column field="DOB" header="DOB" style={{minWidth: '10rem'}}/>
                    <Column field="TestDate" header="Test Date" style={{minWidth: '8rem'}}/>
                </DataTable>
            </Card>
        </section>
    );
}
