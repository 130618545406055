import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import Header from "../components/Header";
import Menu from "../components/Menu";
import {Dropdown} from "primereact/dropdown";
import {useHistory} from "react-router-dom";

export default function AddCrashCourse() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [leadData, setLeadData] = useState([]);
    const [testTaker, setTestTaker] = useState('');
    const [cnic, setCnic] = useState('');
    const [dob, setDob] = useState([]);
    const [testDate, setTestDate] = useState([]);
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState("");
    const [examType, setExamType] = useState("");
    const [testType, setTestType] = useState("");
    const [testBody, setTestBody] = useState("");
    const [total, setTotal] = useState("");
    const [registrationDate, setRegistrationDate] = useState("");
    const [result, setResult] = useState("");
    const [recommendation, setRecommendation] = useState("");
    const [trainer, setTrainer] = useState("");
    const [invigilator, setInvigilator] = useState("");
    const [slipNo, setSlipNo] = useState("");
    const [batch, setBatch] = useState("");
    const [branch, setBranch] = useState("");
    // const [branchData, setBranchData] = useState("");
    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);

        if (res.id) {
            LoadFollowDetail(res.id);
        }

    }, []);

    async function LoadFollowDetail(id) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadCrashCourse");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setLeadData(Data);
                    Data = Data[0];
                    setTestTaker(Data.Taker)
                    setCnic(Data.CNIC)
                    setTestDate(Data.TestDate)
                    setDob(Data.DOB)
                    setContact(Data.ContactNo)
                    setEmail(Data.Email)
                    setExamType(Data.ExamType)
                    setTestType(Data.TestType)
                    setTestBody(Data.TestBody)
                    setTotal(Data.Total)
                    setRecommendation(Data.Recommendation)
                    setTrainer(Data.Trainer)
                    setInvigilator(Data.Invigilator)
                    setBranch(Data.Branch)
                    setBatch(Data.Batch)
                    setSlipNo(Data.SlipNo)
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }


    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("CreatedBy", localStorage.getItem("UserId"));
        if (leadData.length > 0) {
            formdata.append("page", "UpdateCrashCourse");
            formdata.append("id", leadData[0].id);
        } else {
            formdata.append("page", "SaveCrashCourse");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    // let Data = result.Data;
                    setTestTaker("")
                    setCnic("")
                    setTestDate("")
                    setDob("")
                    setContact("")
                    setEmail("")
                    setExamType("")
                    setTestType("")
                    setTestBody("")
                    setTotal("")
                    setRecommendation("")
                    setTrainer("")
                    setInvigilator("")
                    setBranch("")
                    setBatch("")
                    setSlipNo("")
                    $('#Form').trigger('reset');
                    showSuccess(leadData.length > 0 ? "Record Updated Successfully" : "Record Saved Successfully");
                    setTimeout(()=>{
                        navigate.go(-1)
                    },1000)
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Add Crash Course</span>
                        </>
                    )
                }}
                className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Test Taker <code>*</code></label>
                                <InputText value={testTaker}
                                           onChange={(e) => setTestTaker(e.target.value)} name='taker'
                                           placeholder={'.....'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>CNIC / Passport <code>*</code></label>
                                <InputText value={cnic} type={'number'}
                                           onChange={(e) => setCnic(e.target.value)} name='cnic'
                                           placeholder={'.....'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Date of Birth <code>*</code></label>
                                <InputText value={dob} type={'date'}
                                           onChange={(e) => setDob(e.target.value)} name='dob'
                                           placeholder={'.....'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Test Date <code>*</code></label>
                                <InputText value={testDate} type={'date'}
                                           onChange={(e) => setTestDate(e.target.value)} name='testDate'
                                           placeholder={'.....'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Contact No <code>*</code></label>
                                <InputText value={contact} type={'number'}
                                           onChange={(e) => setContact(e.target.value)} name='contact'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Email Address <code>*</code></label>
                                <InputText value={email}
                                           onChange={(e) => setEmail(e.target.value)} name='email'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Exam Type <code>*</code></label>
                                <Dropdown name={'examType'} value={examType} onChange={(e) => setExamType(e.value)}
                                          options={[
                                              {name: "CD", value: "CD"},
                                              {name: "Paper Type", value: "Paper Type"},
                                          ]} optionLabel="name"
                                          placeholder="Select Exam Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Test Type <code>*</code></label>
                                <InputText value={testType}
                                           onChange={(e) => setTestType(e.target.value)} name='testType'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Test Body <code>*</code></label>
                                <InputText value={testBody}
                                           onChange={(e) => setTestBody(e.target.value)} name='body'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Total Test Attempted <code>*</code></label>
                                <InputText value={total} type={'number'}
                                           onChange={(e) => setTotal(e.target.value)} name='total'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Recommendation <code>*</code></label>
                                <InputText value={recommendation}
                                           onChange={(e) => setRecommendation(e.target.value)} name='recommendation'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Trainer <code>*</code></label>
                                <InputText value={trainer}
                                           onChange={(e) => setTrainer(e.target.value)} name='trainer'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Invigilator <code>*</code></label>
                                <InputText value={invigilator}
                                           onChange={(e) => setInvigilator(e.target.value)} name='invigilator'
                                           placeholder={'.....'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Payment/Slip No<code>*</code></label>
                                <InputText value={slipNo}
                                           onChange={(e) => setSlipNo(e.target.value)} name='slip'
                                           placeholder={'.....'}/>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Batch <code>*</code></label>
                                <Dropdown name={'batch'} value={batch} onChange={(e) => setBatch(e.value)}
                                          options={[
                                              {name: "9-1", value: "9-1"},
                                              {name: "4-8", value: "4-8"},
                                          ]} optionLabel="name"
                                          placeholder="Select Batch" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Branch <code>*</code></label>
                                <Dropdown name={'branch'} value={branch} onChange={(e) => setBranch(e.value)} options={[
                                    {
                                        name: "Multan",
                                        value: "Multan"
                                    },
                                    {
                                        name: "Gujranwala",
                                        value: "Gujranwala"
                                    },
                                    {
                                        name: "Sahiwal",
                                        value: "Sahiwal"
                                    },
                                    {
                                        name: "Bahawalpur",
                                        value: "Bahawalpur"
                                    }
                                ]} optionLabel="name"
                                          placeholder="Select a Branch" className='form-control'/>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {
                            leadData.length > 0 ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                    </div>
                </form>
            </Card>
        </section>

    );
}
