import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import $ from "jquery";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import {File} from "react-feather";
import {Dropdown} from "primereact/dropdown";

export default function Visa() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    const [startDate,setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');
    const [branch, setBranch] = useState('');
    const [isDate, setIsDate] = useState(false);
    useEffect(() => {
        LoadData();
    }, [])

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "GetVisas");
        formdata.append("branch", localStorage.getItem("Branch"));
        formdata.append("role", localStorage.getItem("Role"));
        formdata.append("user", localStorage.getItem("UserId"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterData=(e)=>{
        let val = e.target.value.toLowerCase();
        if (val) {
            const filter = filterRecord.filter((d) => {
                return d.LeadNo.toLowerCase().indexOf(val) > -1 || d.Comment.toLowerCase().indexOf(val) > -1 || d.Status.toLowerCase().indexOf(val) > -1 || d.InterviewDate.toLowerCase().indexOf(val) > -1
            })
            console.log(filter);
            setCustomerData(filter);
        }
        else
        {
            setCustomerData(filterRecord);
        }
    }

    function filterDateRecord() {
        if (startDate && endDate) {
            const filter = filterRecord.filter((d) => {
                return d.Date >= startDate && d.Date <= endDate
            })
            console.log(filter);
            setCustomerData(filter);
            setIsDate(true);
        } else {
            toast.current.show({
                severity: 'warn',
                summary: 'Date Empty !',
                detail: 'Please select From and To Date for filter record',
                life: 3000
            });
            setCustomerData(filterRecord);
            setIsDate(false);
        }
    }

    function filterRecordBranch(b) {
        if (b) {
            if (b !== "All") {
                if (isDate) {
                    const filter = customerData.filter((d) => {
                        return d.Branch.toLowerCase() === b.toString().toLowerCase()
                    })
                    console.log(filter);
                    setCustomerData(filter);
                }
                else
                {
                    const filter = filterRecord.filter((d) => {
                        return d.Branch.toLowerCase() === b.toString().toLowerCase()
                    })
                    console.log(filter);
                    setCustomerData(filter);
                }
            }
            else
            {
                setEndDate("")
                setStartDate("")
                setIsDate(false);
                setCustomerData(filterRecord);
            }
        }
        else if (isDate)
        {
            const filter = filterRecord.filter((d) => {
                return d.Date >= startDate && d.Date <= endDate
            })
            console.log(filter);
            setCustomerData(filter);
        }
        else
        {
            setCustomerData(filterRecord);
        }
    }

    return (
        <section className="content">
            <Card header={() => {
                return (
                    <div className="row justify-content-end gap-4 mt-4">
                        <Button onClick={() => history.push('/add-visa')} label="Add Visa"
                                icon="pi pi-plus"
                                style={{width: "auto"}}/>
                    </div>
                )
            }
            }
                  title={() => {
                      return (
                          <>
                              <button onClick={()=> history.go(-1) } style={{ border : "none",outline : "none",background : "transparent" }}><i className={'fas fa-arrow-left'}></i></button>&nbsp;&nbsp;&nbsp;
                              <span>Visa</span>
                              <div className="row justify-content-between">
                                  <div className="col-md-3 mt-1">
                                      <div className="form-group">
                                          <InputText type={'search'} onChange={(e) => filterData(e)}
                                                     placeholder={'Keyword Search...'}/>
                                      </div>
                                  </div>
                                  <div className="col-md-9 mt-1">
                                      <div className="row">
                                          <div className="col-md-3 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'} value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-3 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'} value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-2 mt-1">
                                              <div className="form-group">
                                                  <button onClick={filterDateRecord} style={{marginTop: 10}}
                                                          className={'btn btn-primary form-control'}>Search
                                                  </button>
                                              </div>
                                          </div>
                                          {
                                              localStorage.getItem("Role") == "Admin" ?
                                                  <div className="col-md-4 mt-1">
                                                      <div className="form-group">
                                                          <Dropdown name={'branch'} value={branch} onChange={(e) => {
                                                              setBranch(e.value);
                                                              filterRecordBranch(e.value);
                                                          }} options={[
                                                              {
                                                                  name: "--Select Branch--",
                                                                  value: ""
                                                              },{
                                                                  name: "Multan",
                                                                  value: "Multan"
                                                              },
                                                              {
                                                                  name: "Gujranwala",
                                                                  value: "Gujranwala"
                                                              },
                                                              {
                                                                  name: "Sahiwal",
                                                                  value: "Sahiwal"
                                                              },
                                                              {
                                                                  name: "Bahawalpur",
                                                                  value: "Bahawalpur"
                                                              },
                                                              {
                                                                  name: "All Branches",
                                                                  value: "All"
                                                              }
                                                          ]} optionLabel="name"
                                                                    placeholder="Select a Branch" className='form-control'/>
                                                      </div>
                                                  </div> : null
                                          }

                                      </div>
                                  </div>
                              </div>
                          </>
                      )
                  }}
                  className="card">
                <Toast ref={toast}/>
                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No Applications Found !'} paginator
                           rows={10}
                           onRowClick={(p)=> {
                               let id = customerData[p.index].id;
                               history.push("/add-visa?id="+id);
                           }}
                           rowClassName={'cursor-pointer'}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}>
                    <Column field="LeadNo" header="File No" style={{minWidth: '10rem'}}/>
                    <Column field="Date" header="Interview Date" style={{minWidth: '10rem'}}/>
                    <Column field="Comment" header="Comment" style={{minWidth: '12rem'}}/>
                    <Column header="Visa Grant" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc1) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc1} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Application" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc2) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc2} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="SOP" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc3) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc3} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc4" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc4) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc4} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                    }}/>
                    <Column header="Doc5" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc5) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc5} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>

                    <Column header="Status" style={{minWidth: '10rem'}} body={(p) => {
                        if (p.Status !== "") {
                            return (
                                <div style={{
                                    backgroundColor: function () {
                                        if (p.Status == "Processing")
                                            return "#000"
                                        else if (p.Status == "Rejected")
                                            return "#ED9393"
                                        else if (p.Status == "Granted")
                                            return "#82CCB0"
                                    }.call(this),
                                    padding: "4px 10px",
                                    borderRadius: 5,
                                    textAlign: "center",
                                    color: "#fff"
                                }}>
                                    <span>{p.Status}</span>
                                </div>
                            )
                        } else {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column field="CreatedBy" header="Created By" style={{minWidth: '12rem'}}/>
                    <Column header="Edit" style={{minWidth: '6rem'}} body={(p) => {
                        return (
                            <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                                history.push("/add-visa?id=" + p.id);
                            }}/>
                        )
                    }}/>
                    <Column header="Delete" style={{minWidth: '6rem'}} body={(p) => {
                        const acceptDelete = async () => {
                            var myHeaders = new Headers();
                            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                            var formdata = new FormData();
                            formdata.append("page", "DeleteVisa");
                            formdata.append("id", p.id);
                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdata,
                                redirect: 'follow'
                            };

                            await fetch(`${AppLink}Leads.php`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    console.log(result);
                                    setLoading(false);
                                    if (result.status == "1") {
                                        // let Data = result.Data;
                                        LoadData();
                                        showSuccess("Record Deleted Successfully");
                                    } else {
                                        showError();
                                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                                    }
                                })
                                .catch(error => console.log('error', error));
                        }
                        if (localStorage.getItem("Role") !== "Operator" && localStorage.getItem("Role") !== "Counselor") {
                            return (
                                <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                    confirmPopup({
                                        target: event.currentTarget,
                                        message: 'Do you want to delete this record?',
                                        icon: 'pi pi-info-circle',
                                        acceptClassName: 'p-button-danger',
                                        accept() {
                                            acceptDelete()
                                        },
                                        reject() {
                                            console.log("Reject");
                                        }
                                    });


                                }}/>
                            )
                        }
                        else
                        {
                            <></>
                        }
                    }}/>
                </DataTable>
            </Card>
        </section>
    );
}
