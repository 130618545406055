import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import {Dropdown} from "primereact/dropdown";
import {useHistory} from "react-router-dom";

export default function AddVisa() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [updateId, setUpdateId] = useState('');
    const [date, setDate] = useState('');
    const [usersData, setUsersData] = useState([]);
    const [comment, setComment] = useState("");
    const [status, setStatus] = useState("");
    const [leadNo, setLeadNo] = useState('');
    const [leadNoData, setLeadNoData] = useState([]);

    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);
        LoadUsers();
        LoadLeadNo();
        if (res.id) {
            setUpdateId(res.id);
            LoadFollowDetail(res.id);
        }

    }, []);

    async function LoadFollowDetail(id) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadVisa");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    Data = Data[0];
                    setDate(Data.Date);
                    setComment(Data.Comment);
                    setStatus(Data.Status)
                    setLeadNo(Data.LeadNo)
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadUsers() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadUsers");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    setUsersData(result.data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("CreatedBy", localStorage.getItem("UserId"));
        formdata.append("LeadNo", leadNo);
        if (updateId) {
            formdata.append("page", "UpdateVisa");
            formdata.append("id", updateId);
        } else {
            formdata.append("page", "SaveVisa");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    // let Data = result.Data;
                    $('#Form').trigger('reset');
                    setDate("");
                    setComment("");
                    setStatus("");
                    setLeadNo("");
                    showSuccess(updateId ? "Record Updated Successfully" : "Record Saved Successfully");
                    setUpdateId("");
                    LoadUsers();
                    LoadLeadNo();
                    setTimeout(()=>{
                        navigate.go(-1)
                    },1000)
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadLeadNo() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadLeadNoData");
        formdata.append("role", localStorage.getItem("Role"));
        formdata.append("user", localStorage.getItem("UserId"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    setLeadNoData(result.data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Add Visa</span>
                        </>
                    )
                }}
                className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>File No <code>*</code></label>
                                <Dropdown filter={true} name={'leadNo'} value={leadNo} onChange={(e) => setLeadNo(e.value)}
                                          options={leadNoData} optionLabel="name"
                                          placeholder="Select File No" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Interview/Biometric Date <code>*</code></label>
                                <InputText value={date}
                                           onChange={(e) => setDate(e.target.value)} name='Date'
                                           type={'date'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comment <code>*</code></label>
                                <textarea placeholder={'Enter Comment'} name={'Comment'}
                                          className={'form-control'} value={comment} required={true}
                                          onChange={(e) => setComment(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status <code>*</code></label>
                                <Dropdown name={'Status'} value={status}
                                          onChange={(e) => setStatus(e.value)}
                                          options={[
                                              {name: "Granted", value: "Granted"},
                                              {name: "Rejected", value: "Rejected"},
                                              {name: "Processing", value: "Processing"},
                                          ]} optionLabel="name"
                                          placeholder="Select Status" className='form-control'/>
                            </div>

                        </div>
                        <div className="col-md-6 hide">
                            <div className="form-group">
                                <label>Document 1 <code>*</code></label>
                                <InputText name='doc1' value={'Visa Grant'}
                                           placeholder={'Enter Enter Document Title'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Visa Grant <code>*</code></label>
                                <InputText name='upload1' type={'file'} className={'form-control'}/>
                            </div>
                        </div>
                        <div className="col-md-6 hide">
                            <div className="form-group">
                                <label>Document 2 <code>*</code></label>
                                <InputText name='doc2' value={'Application'}
                                           placeholder={'Enter Enter Document Title'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Application <code>*</code></label>
                                <InputText name='upload2' type={'file'} className={'form-control'}/>
                            </div>
                        </div>
                        <div className="col-md-6 hide">
                            <div className="form-group">
                                <label>Document 3 <code>*</code></label>
                                <InputText name='doc3' value={'SOP'}
                                           placeholder={'Enter Enter Document Title'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>SOP <code>*</code></label>
                                <InputText name='upload3' type={'file'} className={'form-control'}/>
                            </div>
                        </div>
                        <div className={'row'}>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 4 <code>*</code></label>
                                    <InputText name='doc4'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload4' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 5 <code>*</code></label>
                                    <InputText name='doc5'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload5' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-3">
                        {
                            updateId ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
