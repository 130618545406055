import React, {useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import AppLink from "../components/Link";
import {Toast} from "primereact/toast";
import $ from "jquery";

const Login=()=>{
    const history = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }
    const submitHandler=async (e)=>{
        e.preventDefault();
        let form = document.forms.LoginForm;
        let Data =new FormData(form);
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        Data.append("page", "UserLogin");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: Data,
            redirect: 'follow'
        };

        await fetch(`${AppLink}users.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    $('#LoginForm').trigger('reset');
                    localStorage.setItem("UserName",result.UserName);
                    localStorage.setItem("Email",result.Email);
                    localStorage.setItem("Role",result.Role);
                    localStorage.setItem("Branch",result.Branch);
                    localStorage.setItem("Password",result.Password);
                    localStorage.setItem("UserId",result.UserId);
                    setEmail("");
                    setPassword("");
                    showSuccess("Logged In Successfully");
                    setTimeout(()=>{
                        window.location.href="/";
                    },3000)
                    // setCustomerData(Data);
                }
                else if (result.status == "2")
                {
                    toast.current.show({severity: 'warning', summary: 'Error', detail: 'User Not Found !', life: 3000});
                }
                else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }
    return(
        <div className="limiter">
            <Toast ref={toast}/>
            <div className="container-login100">
                <div className="wrap-login100">
                    <form id={'LoginForm'} className="login100-form validate-form" onSubmit={submitHandler}>
					<span className="login100-form-title p-b-45">
						Login
					</span>
                        <div className="wrap-input100 validate-input"
                             data-validate="Valid email is required: ex@abc.xyz">
                            <input className="input100" type="text" name="email" required={true} />
                                <span className="focus-input100"></span>
                                <span className="label-input100">Email</span>
                        </div>


                        <div className="wrap-input100 validate-input" data-validate="Password is required">
                            <input className="input100" type="password" name="password" required={true} />
                                <span className="focus-input100"></span>
                                <span className="label-input100">Password</span>
                        </div>

                        <div className="flex-sb-m w-full p-t-15 p-b-20">
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" value="" /> Remember me
                                        <span className="form-check-sign">
									<span className="check"></span>
								</span>
                                </label>
                            </div>
                        </div>
                        <div className="container-login100-form-btn">
                            <button className="login100-form-btn">
                                {loading ? "Please wait..." : "Login"}
                            </button>
                        </div>
                    </form>
                    <div className="login100-more" style={{ backgroundImage : "url('assets/images/pages/bg-01.png')" }}>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;