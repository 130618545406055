import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import Menu from "../components/Menu";
import {useHistory} from "react-router-dom";

const Home = () => {
    const [type, setType] = useState('');
    const history = useHistory();
    useEffect(()=>{
        let t = localStorage.getItem("Type");
        setType(t);
    },[])
    return (

                <section className="content">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row mb-0">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <ul className="breadcrumb breadcrumb-style ">
                                        <li className="breadcrumb-item">
                                            <h4 className="page-title">Dashboard</h4>
                                        </li>
                                        <li className="breadcrumb-item bcrumb-1">
                                            <a href="index.html">
                                                <i className="fas fa-home"></i> Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="info-box7" style={{ color : "#000" }}>
                                    <div onClick={() => {
                                        setType("Consultancy")
                                        localStorage.setItem("Type","Consultancy");
                                    }} style={{cursor: "pointer"}}
                                         className="info-box7-block">
                                        <h4 className="m-b-20">Consultancy</h4>
                                        <h2 className="text-end"><i className="fas fa-users float-start"></i><span><i
                                            className="fas fa-arrow-circle-right"></i></span>
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <div className={localStorage.getItem("Role") === "Counselor" ? "hide" : "col-lg-3 col-sm-6 "}>
                                <div onClick={() => {
                                    setType("Language");
                                    localStorage.setItem("Type","Language");
                                }}
                                className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                    <div className="info-box7-block">
                                        <h4 className="m-b-20">Language</h4>
                                        <h2 className="text-end"><i
                                            className="fas fa-language float-start"></i><span><i
                                            className="fas fa-arrow-circle-right"></i></span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            type == 'Consultancy' ?
                                <div className="row">
                                    <div className={localStorage.getItem("Role") === "Counselor" ? "hide" : "col-lg-3 col-sm-6 "} onClick={()=> history.push("/leads-list")}>
                                        <div className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Leads</h4>
                                                <h2 className="text-end"><i
                                                    className="fas fa-cogs float-start"></i><span>&nbsp;</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={localStorage.getItem("Role") === "Counselor" ? "hide" : "col-lg-3 col-sm-6 "} onClick={()=> history.push("/follow-up")}>
                                        <div className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Follow Up</h4>
                                                <h2 className="text-end"><i
                                                    className="fas fa-book float-start"></i><span>&nbsp;</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={localStorage.getItem("Role") === "Operator" ? "hide" : "col-lg-3 col-sm-6 "} onClick={()=> history.push("/applications")}>
                                        <div className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Applications</h4>
                                                <h2 className="text-end"><i
                                                    className="fas fa-award float-start"></i><span>&nbsp;</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={localStorage.getItem("Role") === "Operator" ? "hide" : "col-lg-3 col-sm-6 "} onClick={()=> history.push("/visa")}>
                                        <div className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Visa</h4>
                                                <h2 className="text-end"><i
                                                    className="fas fa-users float-start"></i><span>&nbsp;</span></h2>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={localStorage.getItem("Role") === "Operator" ? "hide" : "col-lg-3 col-sm-6 "} onClick={()=> history.push("/gte")}>
                                        <div className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">GTE</h4>
                                                <h2 className="text-end"><i
                                                    className="fas fa-file float-start"></i><span>&nbsp;</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={localStorage.getItem("Role") === "Operator" || localStorage.getItem("Role") === "Counselor"  ? "hide" : "col-lg-3 col-sm-6 "} onClick={()=> history.push("/status")}>
                                        <div className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Status</h4>
                                                <h2 className="text-end"><i
                                                    className="fas fa-users float-start"></i><span>&nbsp;</span></h2>
                                            </div>
                                        </div>
                                    </div>

                                </div> : null
                        }
                        {
                            type == "Language" ?
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6" onClick={()=> history.push("/ielts")}>
                                        <div className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">IELTS</h4>
                                                <h2 className="text-end"><i
                                                    className="fas fa-globe float-start"></i><span>&nbsp;</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6" onClick={()=> history.push("/pte")}>
                                        <div className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">PTE</h4>
                                                <h2 className="text-end"><i
                                                    className="fas fa-globe float-start"></i><span>&nbsp;</span></h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6" onClick={()=> history.push("/spoken")}>
                                        <div className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Spoken</h4>
                                                <h2 className="text-end"><i
                                                    className="fas fa-globe float-start"></i><span>&nbsp;</span></h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6" onClick={()=> history.push("/tofel")}>
                                        <div className="info-box7" style={{ color : "#000",cursor:"pointer" }}>
                                            <div className="info-box7-block">
                                                <h4 className="m-b-20">Other Tests</h4>
                                                <h2 className="text-end"><i
                                                    className="fas fa-globe float-start"></i><span>&nbsp;</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }

                    </div>
                </section>

    )
}
export default Home;
