import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import $ from "jquery";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import {File} from "react-feather";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog";

export default function Status() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [customerData, setCustomerData] = useState([]);
    const [search, setSearch] = useState('');
    const [visible, setVisible] = useState(false);
//    Fields
    const [leadData, setLeadData] = useState([]);
    const [updateId, setUpdateId] = useState('');
    const [name, setName] = useState('');
    const [leadNo, setLeadNo] = useState('');
    const [fname, setFname] = useState('');
    const [lemail, setLemail] = useState('');
    const [phone, setPhone] = useState('');
    const [school, setSchool] = useState('');
    const [test, setTest] = useState('');
    const [fileNo, setFileNo] = useState('');
    const [payment, setPayment] = useState('');
    const [country, setCountry] = useState('');
    const [lassignTo, setLassignTo] = useState("");
    const [status, setStatus] = useState("");
    const [usersData, setUsersData] = useState([]);
    const [parentType, setParentType] = useState('');
    const [callResponse, setCallResponse] = useState("");
    const [priority, setPriority] = useState("");
    const [type, setType] = useState("");
    const [department, setDepartment] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [related, setRelated] = useState("");
    const [assignTo, setAssignTo] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forwardTo, setForwardTo] = useState("");

    const [leadNoData, setLeadNoData] = useState([]);
    const [date, setDate] = useState('');
    const [AassignTo, setAassignTo] = useState("");
    const [subject, setSubject] = useState("");
    const [intake, setIntake] = useState("");
    const [university, setUniversity] = useState("");
    const [offer, setOffers] = useState("");
    const [rejection, setRejection] = useState("");
    const [finalize, setFinalize] = useState("");
    const [stage, setStage] = useState("");
    const [other, setOther] = useState("");
    const [Astatus, setAstatus] = useState("");


    const [interviewDate, setInterviewDate] = useState('');
    const [comment, setComment] = useState("");
    const [Vstatus, setVstatus] = useState("");

//Expansion
    const [expandedRows, setExpandedRows] = useState(null);

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const searchRecord = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "GetStatus");
        formdata.append("Search", search);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }


    const rowExpansionTemplate = (data) => {
        let arr = [data];
        return (
            <div className="p-3">
                <h5>Leads Data</h5>
                <DataTable value={arr}>
                    <Column field="Country" header="Country Preferred" style={{minWidth: '10rem'}}/>
                    <Column field="Email" header="Email Address" style={{minWidth: '12rem'}}/>
                    <Column field="School" header="Qualification" style={{minWidth: '10rem'}}/>
                    <Column field="Test" header="English Test" style={{minWidth: '10rem'}}/>
                    <Column field="VoucherNo" header="Payment/Voucher No" style={{minWidth: '10rem'}}/>
                    <Column header="Receipt" style={{minWidth: '10rem'}} body={(p) => {
                        return (
                            <a href={ImageLink + p.VoucherImage} target={'_blank'}>
                                <img src={ImageLink + p.VoucherImage} style={{width: 80, height: 80, borderRadius: 4}}/>
                            </a>
                        )
                    }}/>
                    <Column field="LeadAssign" header="Assigned To" style={{minWidth: '10rem'}}/>
                </DataTable>
                <h5 className={'mt-3'}>Follow Up Data</h5>
                <DataTable value={arr}>
                    <Column field="FollowEmail" header="Official Email" style={{minWidth: '10rem'}}/>
                    <Column field="FollowPassword" header="Password" style={{minWidth: '10rem'}}/>
                    <Column field="ParentType" header="Parent Type" style={{minWidth: '10rem'}}/>
                    <Column field="CallResponse" header="Call Response" style={{minWidth: '10rem'}}/>
                    <Column field="Priority" header="Priority" style={{minWidth: '10rem'}}/>
                    <Column field="Type" header="Type" style={{minWidth: '10rem'}}/>
                    <Column field="Department" header="Department" style={{minWidth: '10rem'}}/>
                    <Column field="DueDate" header="Due Date" style={{minWidth: '10rem'}}/>
                    <Column field="Related" header="Related" style={{minWidth: '10rem'}}/>
                    <Column field="FollowAssign" header="AssignTo" style={{minWidth: '10rem'}}/>
                    <Column field="ForwardTo" header="ForwardTo" style={{minWidth: '8rem'}}/>
                </DataTable>
                <h5 className={'mt-3'}>Application Data</h5>
                <DataTable value={arr}>
                    <Column field="Rejection" header="Rejection" style={{minWidth: '10rem'}}/>
                    <Column field="Finalize" header="Finalize Option" style={{minWidth: '10rem'}}/>
                    <Column field="Stage" header="Stage" style={{minWidth: '10rem'}}/>
                    <Column field="Other" header="Other Stage" style={{minWidth: '10rem'}}/>
                    <Column header="Document 1" style={{minWidth: '6rem'}} body={(p) => {
                        if (p.AD1) {
                            return (
                                <a href={ImageLink + p.AD1} target={'_blank'}>
                                    <span>{p.AD1}</span>&nbsp;&nbsp;<File/>
                                </a>
                            )
                        } else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Document 2" style={{minWidth: '6rem'}} body={(p) => {
                        if (p.AD2) {
                            return (
                                <a href={ImageLink + p.AD2} target={'_blank'}>
                                    <span>{p.AD2}</span>&nbsp;&nbsp;<File/>
                                </a>
                            )
                        } else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Document 3" style={{minWidth: '6rem'}} body={(p) => {
                        if (p.AD3) {
                            return (
                                <a href={ImageLink + p.AD3} target={'_blank'}>
                                    <span>{p.AD3}</span>&nbsp;&nbsp;<File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Document 4" style={{minWidth: '6rem'}} body={(p) => {
                        if (p.AD4) {
                            return (
                                <a href={ImageLink + p.AD4} target={'_blank'}>
                                    <span>{p.AD4}</span>&nbsp;&nbsp;<File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Document 5" style={{minWidth: '6rem'}} body={(p) => {
                        if (p.AD5) {
                            return (
                                <a href={ImageLink + p.AD5} target={'_blank'}>
                                    <span>{p.AD5}</span>&nbsp;&nbsp;<File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>

                    <Column header="Status" style={{minWidth: '8rem'}} body={(p) => {
                        if (p.ApplicationStatus !== "") {
                            return (
                                <div style={{
                                    backgroundColor: function () {
                                        if (p.ApplicationStatus == "Pending")
                                            return "#000"
                                        else if (p.ApplicationStatus == "Rejected")
                                            return "#ED9393"
                                        else if (p.ApplicationStatus == "Accepted")
                                            return "#82CCB0"
                                    }.call(this),
                                    padding: "4px 10px",
                                    borderRadius: 5,
                                    textAlign: "center",
                                    color: "#fff"
                                }}>
                                    <span>{p.ApplicationStatus}</span>
                                </div>
                            )
                        } else {
                            return (
                                <></>
                            )
                        }
                    }}/>
                </DataTable>
                <h5 className={'mt-3'}>Visa Data</h5>
                <DataTable value={arr}>
                    <Column field="InterviewDate" header="Interview Date" style={{minWidth: '10rem'}}/>
                    <Column field="Comment" header="Comment" style={{minWidth: '12rem'}}/>
                    <Column header="Document 1" style={{minWidth: '10rem'}} body={(p) => {
                        if (p.VD1) {
                            return (
                                <a href={ImageLink + "Visa/" + p.VD1} target={'_blank'}>
                                    <span>{p.VD1}</span>&nbsp;&nbsp;<File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Document 2" style={{minWidth: '10rem'}} body={(p) => {
                        if (p.VD2) {
                            return (
                                <a href={ImageLink + "Visa/" + p.VD2} target={'_blank'}>
                                    <span>{p.VD2}</span>&nbsp;&nbsp;<File/>
                                </a>
                            )
                        }else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Document 3" style={{minWidth: '10rem'}} body={(p) => {
                        if (p.VD3) {
                            return (
                                <a href={ImageLink + "Visa/" + p.VD3} target={'_blank'}>
                                    <span>{p.VD3}</span>&nbsp;&nbsp;<File/>
                                </a>
                            )
                        }else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Document 4" style={{minWidth: '10rem'}} body={(p) => {
                        if (p.VD4) {
                            return (
                                <a href={ImageLink + "Visa/" + p.VD4} target={'_blank'}>
                                    <span>{p.VD4}</span>&nbsp;&nbsp;<File/>
                                </a>
                            )
                        }
                    }}/>
                    <Column header="Document 5" style={{minWidth: '10rem'}} body={(p) => {
                        if (p.VD5) {
                            return (
                                <a href={ImageLink + "Visa/" + p.VD5} target={'_blank'}>
                                    <span>{p.VD5}</span>&nbsp;&nbsp;<File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>

                    <Column header="Status" style={{minWidth: '10rem'}} body={(p) => {
                        if (p.VisaStatus !== "") {
                            return (
                                <div style={{
                                    backgroundColor: function () {
                                        if (p.VisaStatus == "Pending")
                                            return "#000"
                                        else if (p.VisaStatus == "Rejected")
                                            return "#ED9393"
                                        else if (p.VisaStatus == "Accepted")
                                            return "#82CCB0"
                                    }.call(this),
                                    padding: "4px 10px",
                                    borderRadius: 5,
                                    textAlign: "center",
                                    color: "#fff"
                                }}>
                                    <span>{p.VisaStatus}</span>
                                </div>
                            )
                        } else {
                            return (
                                <></>
                            )
                        }
                    }}/>
                </DataTable>
            </div>
        );
    };


    return (
        <section className="content">
            <Card title={() => {
                return (
                    <>
                        <button onClick={() => history.go(-1)}
                                style={{border: "none", outline: "none", background: "transparent"}}><i
                            className={'fas fa-arrow-left'}></i></button>
                        &nbsp;&nbsp;&nbsp;
                        <span>Check Status</span>
                        <div className="row mt-5">
                            <div className="col-md-3 mt-1">
                                <div className="form-group">
                                    <InputText type={'search'} value={search}
                                               onChange={(e) => setSearch(e.target.value)}
                                               placeholder={'Enter LeadNo/FileNo/Name/Phone'}/>
                                </div>
                            </div>
                            <div className="col-md-3 mt-1">
                                <div className="form-group">
                                    <button onClick={searchRecord}
                                            className={'btn btn-primary form-control'}>Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }}
                  className="card">
                <Toast ref={toast}/>
                <Dialog header="Status Detail" visible={visible} style={{width: '50vw'}} onHide={() => setVisible(false)}>

                    <div className="row">
                        <h5>Lead Detail</h5>
                        <div className="col-md-6">
                            <label>Lead No <code>Auto Generated</code></label>
                            <InputText id="LeadNo" value={leadNo} disabled={true}
                                       required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="Name">Name <code>*</code></label>
                            <InputText id="Name" value={name} onChange={(e) => setName(e.target.value)} name='name'
                                       placeholder={'Enter Name'} disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Father Name <code>*</code></label>
                            <InputText id="FatherName" value={fname} onChange={(e) => setFname(e.target.value)}
                                       name='fathername' placeholder={'Enter Father Name'} required={true}
                                       disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Email Address <code>*</code></label>
                            <InputText keyfilter={'email'} value={email} onChange={(e) => setEmail(e.target.value)}
                                       id="Email" name='email' placeholder={'Enter Email Address'} required={true}
                                       disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Phone No <code>*</code></label>
                            <InputText keyfilter={'num'} value={phone} onChange={(e) => setPhone(e.target.value)}
                                       id="PhoneNo" name='phone' placeholder={'Enter Phone No'} required={true}
                                       disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Qualification</label>
                            <InputText id="School" value={school} onChange={(e) => setSchool(e.target.value)}
                                       placeholder={'Enter Qualification'} name='school' required={false}
                                       disabled={true}/>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>English Test <code>*</code></label>
                                <Dropdown name={'test'} value={test} disabled={true}
                                          onChange={(e) => setTest(e.target.value)}
                                          options={[
                                              {
                                                  name: "IELTS",
                                                  value: "IELTS"
                                              },
                                              {
                                                  name: "PTE",
                                                  value: "PTE"
                                              },
                                              {
                                                  name: "Language Cert",
                                                  value: "Language Cert"
                                              },
                                              {
                                                  name: "Other",
                                                  value: "Other"
                                              },
                                              {
                                                  name: "Pending",
                                                  value: "Pending"
                                              }
                                          ]} optionLabel="name"
                                          placeholder="Select Test" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Payment / Voucher No <code>*</code></label>
                            <InputText id="payment" value={payment} onChange={(e) => setPayment(e.target.value)}
                                       placeholder={'Enter Payment/Voucher No'} name='payment' required={true}
                                       disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>File No <code>*</code></label>
                            <InputText id="FileNo" value={fileNo} onChange={(e) => setFileNo(e.target.value)}
                                       placeholder={'Enter File No'} name='fileno' required={true} disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Country Preferred <code>*</code></label>
                            <InputText id="Country" value={country} onChange={(e) => setCountry(e.target.value)}
                                       placeholder={'Enter Country Name'} name='country' required={true}
                                       disabled={true}/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Assign To <code>*</code></label>
                                <InputText id="LeadNo" value={lassignTo} disabled={true}
                                           required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status</label>
                                <Dropdown name={'status'} disabled={true} value={status}
                                          onChange={(e) => setStatus(e.value)}
                                          options={[
                                              {name: "Pending", value: "Pending"},
                                              {name: "Processing", value: "Processing"},
                                              {name: "Rejected", value: "Rejected"},
                                              {name: "Completed", value: "Completed"}
                                          ]} optionLabel="name"
                                          placeholder="Select Status" className='form-control'/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h5>FollowUp Detail</h5>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Lead No <code>*</code></label>
                                <InputText value={leadNo} disabled={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Parent Type <code>*</code></label>
                                <Dropdown name={'parentType'} value={parentType}
                                          onChange={(e) => setParentType(e.value)} disabled={true}
                                          options={[
                                              {name: "Lead", value: "Lead"},
                                              {name: "Task", value: "Task"},
                                              {name: "Application", value: "Application"},
                                              {name: "Contact", value: "Contact"},
                                              {name: "Visa", value: "Visa"},
                                          ]} optionLabel="name"
                                          placeholder="Select Parent Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Call Response <code>*</code></label>
                                <Dropdown name={'callResponse'} value={callResponse}
                                          onChange={(e) => setCallResponse(e.value)} disabled={true}
                                          options={[
                                              {name: "Attended", value: "Attended"},
                                              {name: "NCR", value: "NRC"},
                                              {name: "Powered Off", value: "Powered Off"},
                                          ]} optionLabel="name"
                                          placeholder="Select Call Response" className='form-control'/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Priority <code>*</code></label>
                                <Dropdown name={'priority'} value={priority} onChange={(e) => setPriority(e.value)}
                                          disabled={true}
                                          options={[
                                              {name: "High", value: "High"},
                                              {name: "Medium", value: "Medium"},
                                              {name: "Low", value: "Low"},
                                          ]} optionLabel="name"
                                          placeholder="Select Priority Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Type <code>*</code></label>
                                <Dropdown name={'type'} value={type} onChange={(e) => setType(e.value)} disabled={true}
                                          options={[
                                              {name: "Pre-mature", value: "Pre-mature"},
                                              {name: "Task", value: "Task"},
                                              {name: "Call", value: "Call"},
                                              {name: "Appointment", value: "Appointment"},
                                          ]} optionLabel="name"
                                          placeholder="Select Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Department Name <code>*</code></label>
                                <InputText id="department" value={department}
                                           onChange={(e) => setDepartment(e.target.value)} disabled={true}
                                           name='department'
                                           placeholder={'Enter Department Name'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Due Date <code>*</code></label>
                                <InputText type={'date'} name={'dueDate'} value={dueDate} disabled={true}
                                           onChange={(e) => setDueDate(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Related <code>*</code></label>
                                <Dropdown name={'related'} value={related} disabled={true}
                                          onChange={(e) => setRelated(e.value)}
                                          options={[
                                              {name: "Equal", value: "Equal"},
                                              {name: "Before", value: "Before"},
                                              {name: "After", value: "After"},
                                              {name: "Last 7 days", value: "Last 7 days"},
                                              {name: "Last Month", value: "Last Month"},
                                          ]} optionLabel="name"
                                          placeholder="Select Start Date" className='form-control'/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Assign To <code>*</code></label>
                                <InputText value={assignTo} disabled={true}/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label>Official Email <code>*</code></label>
                            <InputText id="email" value={email} disabled={true}
                                       onChange={(e) => setEmail(e.target.value)} name='email'
                                       placeholder={'Enter Email Address'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Email Password <code>*</code></label>
                            <InputText id="password" value={password} disabled={true}
                                       onChange={(e) => setPassword(e.target.value)}
                                       name='password'
                                       placeholder={'Enter Password'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Forward To <code>*</code></label>
                                <InputText value={forwardTo} disabled={true}/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <h5>Application Detail</h5>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Lead No <code>*</code></label>
                                <InputText value={leadNo} disabled={true} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Registration Date <code>*</code></label>
                                <InputText id="date" value={date}
                                           onChange={(e) => setDate(e.target.value)} name='date' disabled={true}
                                           type={'date'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Assign To <code>*</code></label>
                                <InputText value={assignTo} disabled={true} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Country <code>*</code></label>
                                <InputText id="country" value={country}
                                           onChange={(e) => setCountry(e.target.value)} name='country' disabled={true}
                                           placeholder={'Enter Country Name'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Subject / Course <code>*</code></label>
                                <InputText id="course" value={subject} disabled={true}
                                           onChange={(e) => setSubject(e.target.value)} name='course'
                                           placeholder={'Enter Subject/Course Detail'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Intake <code>*</code></label>
                                <InputText id="intake" value={intake} disabled={true}
                                           onChange={(e) => setIntake(e.target.value)} name='intake'
                                           placeholder={'Enter Intake Value'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Universities Applied <code>*</code></label>
                                <textarea placeholder={'Enter University Names'} id={'university'} name={'university'} disabled={true}
                                          className={'form-control'} value={university}
                                          onChange={(e) => setUniversity(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Offer Received <code>*</code></label>
                                <textarea placeholder={'Enter Offer Received Detail'} id={'offers'} name={'offers'} disabled={true}
                                          className={'form-control'} value={offer}
                                          onChange={(e) => setOffers(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Rejection <code>*</code></label>
                                <InputText id="rejection" value={rejection}
                                           onChange={(e) => setRejection(e.target.value)} name='rejection' disabled={true}
                                           placeholder={'Enter Rejection Detail'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Finalized Option <code>*</code></label>
                                <InputText id="final" value={finalize}
                                           onChange={(e) => setFinalize(e.target.value)} name='final' disabled={true}
                                           placeholder={'Enter Finalized Option'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Stage <code>*</code></label>
                                <Dropdown name={'stage'} value={stage}
                                          onChange={(e) => setStage(e.value)} disabled={true}
                                          options={[
                                              {name: "Application", value: "Application"},
                                              {name: "Proceeding", value: "Proceeding"},
                                              {name: "GTE/Interviews", value: "GTE/Interviews"},
                                              {name: "CAS/COE", value: "CAS/COE"},
                                              {name: "Other", value: "Other"},
                                          ]} optionLabel="name"
                                          placeholder="Select Stage" className='form-control'/>
                            </div>
                        </div>
                        {
                            stage == "Other" ?
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Other Stage <code>*</code></label>
                                        <InputText id="other" value={other} disabled={true}
                                                   onChange={(e) => setOther(e.target.value)} name='other'
                                                   placeholder={'Enter Other Stage'}/>
                                    </div>
                                </div> : null
                        }
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status <code>*</code></label>
                                <Dropdown name={'Status'} value={Astatus} disabled={true}
                                          options={[
                                              {name: "Pending", value: "Pending"},
                                              {name: "Rejected", value: "Rejected"},
                                              {name: "Accepted", value: "Accepted"},
                                          ]} optionLabel="name"
                                          placeholder="Select Status" className='form-control'/>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <h5>Visa Detail</h5>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Lead No <code>*</code></label>
                                <InputText value={leadNo} disabled={true} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Interview Date <code>*</code></label>
                                <InputText value={interviewDate}
                                           onChange={(e) => setDate(e.target.value)} name='Date'
                                           type={'date'} required={true} disabled={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comment <code>*</code></label>
                                <textarea placeholder={'Enter Comment'} name={'Comment'}
                                          className={'form-control'} value={comment} disabled={true}
                                          onChange={(e) => setComment(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status <code>*</code></label>
                                <Dropdown name={'Status'} value={Vstatus}
                                          onChange={(e) => setStatus(e.value)} disabled={true}
                                          options={[
                                              {name: "Pending", value: "Pending"},
                                              {name: "Rejected", value: "Rejected"},
                                              {name: "Accepted", value: "Accepted"},
                                          ]} optionLabel="name"
                                          placeholder="Select Status" className='form-control'/>
                            </div>
                        </div>
                    </div>

                </Dialog>
                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No File/Record Found !'} paginator
                           rows={10}
                           onRowClick={async (p) => {
                               let id = customerData[p.index].id;
                               setLeadNo(customerData[p.index].LeadNo);
                               setName(customerData[p.index].Name);
                               setFname(customerData[p.index]["Father Name"]);
                               setLemail(customerData[p.index].Email);
                               setPhone(customerData[p.index].Phone);
                               setSchool(customerData[p.index].School);
                               setTest(customerData[p.index].Test);
                               setFileNo(customerData[p.index].FileNo);
                               setCountry(customerData[p.index].Country);
                               setLassignTo(customerData[p.index].LeadAssign);
                               setStatus(customerData[p.index].LeadStatus);
                               setPayment(customerData[p.index].VoucherNo)
                               setParentType(customerData[p.index].ParentType);
                               setCallResponse(customerData[p.index].CallResponse);
                               setPriority(customerData[p.index].Priority);
                               setType(customerData[p.index].Type);
                               setDepartment(customerData[p.index].Department);
                               setDueDate(customerData[p.index].DueDate);
                               setRelated(customerData[p.index].Related);
                               setAssignTo(customerData[p.index].FollowAssign);
                               setForwardTo(customerData[p.index].ForwardTo);
                               setEmail(customerData[p.index].FollowEmail);
                               setPassword(customerData[p.index].FollowPassword);


                               //Application
                               setDate(customerData[p.index].ApplicationDate);
                               setAassignTo(customerData[p.index].ApplicationAssign);
                               setCountry(customerData[p.index].Country);
                               setSubject(customerData[p.index].Subject);
                               setIntake(customerData[p.index].Intake);
                               setUniversity(customerData[p.index].University);
                               setOffers(customerData[p.index].Offer);
                               setRejection(customerData[p.index].Rejection);
                               setFinalize(customerData[p.index].Finalize);
                               setStage(customerData[p.index].Stage);
                               setAstatus(customerData[p.index].ApplicationStatus);

                               //Visa
                               setInterviewDate(customerData[p.index].InterviewDate);
                               setComment(customerData[p.index].Comment);
                               setVstatus(customerData[p.index].VisaStatus);

                               setUpdateId(id);
                               setVisible(true);
                           }}
                           rowClassName={'cursor-pointer'}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}
                           expandedRows={expandedRows} onRowToggle={(e) => {
                    setExpandedRows(e.data);
                }}
                           rowExpansionTemplate={rowExpansionTemplate}
                >
                    <Column expander={true} style={{width: '5rem'}}/>
                    <Column field="LeadNo" header="Lead No" style={{minWidth: '10rem'}}/>
                    <Column field="FileNo" header="File No" style={{minWidth: '10rem'}}/>
                    <Column field="Name" header="Name" style={{minWidth: '10rem'}}/>
                    <Column field="Father Name" header="Father Name" style={{minWidth: '10rem'}}/>
                    <Column field="Phone" header="Phone No" style={{minWidth: '10rem'}}/>
                    <Column field="Email" header="Email Address" style={{minWidth: '10rem'}}/>
                    {/*<Column field="Password" header="Password" style={{minWidth: '10rem'}}/>*/}
                </DataTable>
            </Card>
        </section>
    );
}
