import './App.css';
import React from "react";
import Header from "./components/Header";
import Menu from "./components/Menu";
import {Route, Switch} from "react-router-dom";
import Home from "./screens/Home";
import LeadsList from "./screens/LeadsList";
import AddLeads from "./screens/AddLeads";
import NotFound from "./screens/NotFound";
import Login from "./screens/Login";
import UsersList from "./screens/UsersList";
import AddUser from "./screens/AddUser";
import FollowUp from "./screens/FollowUp";
import AddFollowUp from "./screens/AddFollowUp";
import AddTask from "./screens/AddTask";
import Task from "./screens/Task";
import IELTS from "./screens/IELTS";
import AddStudent from "./screens/AddStudent";
import PTE from "./screens/PTE";
import Tofel from "./screens/Tofel";
import Spoken from "./screens/Spoken";
import Applications from "./screens/Applications";
import AddApplication from "./screens/AddApplication";
import Visa from "./screens/Visa";
import AddVisa from "./screens/AddVisa";
import Status from "./screens/Status";
import GTE from "./screens/GTE";
import AddGTE from "./screens/AddGTE";
import CrashCourse from "./screens/CrashCourse";
import AddCrashCourse from "./screens/AddCrashCourse";

function App() {
    return (
        <>
            {/*<div className="loader"></div>*/}
            <Switch>
                <Route exact path='/login' component={Login}/>

                <div id="app">
                    <div className="main-wrapper main-wrapper-1">
                        <Header/>
                        <Menu/>
                        <Route exact path='/' component={Home}/>
                        <Route exact path='/users' component={UsersList}/>
                        <Route exact path='/add-user' component={AddUser}/>
                        <Route exact path='/leads-list' component={LeadsList}/>
                        <Route exact path='/add-leads' component={AddLeads}/>
                        <Route exact path='/follow-up' component={FollowUp}/>
                        <Route exact path='/tasks' component={Task}/>
                        <Route exact path='/ielts' component={IELTS}/>
                        <Route exact path='/pte' component={PTE}/>
                        <Route exact path='/spoken' component={Spoken}/>
                        <Route exact path='/tofel' component={Tofel}/>
                        <Route exact path='/add-task' component={AddTask}/>
                        <Route exact path='/add-follow' component={AddFollowUp}/>
                        <Route exact path='/add-student' component={AddStudent}/>
                        <Route exact path='/applications' component={Applications}/>
                        <Route exact path='/visa' component={Visa}/>
                        <Route exact path='/gte' component={GTE}/>
                        <Route exact path='/add-application' component={AddApplication}/>
                        <Route exact path='/add-visa' component={AddVisa}/>
                        <Route exact path='/add-gte' component={AddGTE}/>
                        <Route exact path='/status' component={Status}/>
                        <Route exact path='/crash' component={CrashCourse}/>
                        <Route exact path='/add-crash' component={AddCrashCourse}/>

                    </div>
                </div>
                <Route component={NotFound}/>
            </Switch>


            {/*<div id="app">*/}
            {/*    <div className="main-wrapper main-wrapper-1">*/}

            {/*        <Header />*/}
            {/*        <Menu />*/}

            {/*        <Footer />*/}

            {/*    </div>*/}
            {/*</div>*/}

        </>
    );
}

export default App;
