import React, {useEffect} from "react";
import {NavLink, useHistory} from "react-router-dom";

const Header = () => {
    const history = useHistory();

    function Logout() {
        localStorage.setItem("UserName","");
        localStorage.setItem("Email","");
        localStorage.setItem("Role","");
        localStorage.setItem("Password","");
        localStorage.setItem("UserId","");
        history.push('/login');
    }

    useEffect(()=>{
        if (!localStorage.getItem("UserName") || !localStorage.getItem("Email"))
        {
            history.push('/login');
        }
    },[])


    return (
        <>
            <nav className="navbar">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <a href="#" onClick={()=> {
                            return false;
                        }} className="navbar-toggle collapsed" data-bs-toggle="collapse"
                           data-target="#navbar-collapse" aria-expanded="false"></a>
                        <a href="#" onClick={()=> {
                            return false;
                        }} className="bars"></a>
                        <NavLink to={'/'} exact className="navbar-brand" href="#">
                            <img src="assets/images/logo.png" alt="" />
                            <span className="logo-name">A.E.C</span>
                        </NavLink>
                    </div>
                    <div className="collapse navbar-collapse" id="navbar-collapse">
                        <ul className="float-start">
                            <li>
                                <a href="#" onClick={()=> {
                                    return false;
                                }} className="sidemenu-collapse">
                                    <i data-feather="menu"></i>
                                </a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li className="fullscreen">
                                <a href="#" className="fullscreen-btn">
                                    <i className="fas fa-expand"></i>
                                </a>
                            </li>
                            {/*<li className="dropdown">*/}
                            {/*    <a href="#" onClick={()=> {*/}
                            {/*        return false;*/}
                            {/*    }} className="dropdown-toggle" data-bs-toggle="dropdown"*/}
                            {/*       role="button">*/}
                            {/*        <i className="far fa-bell"></i>*/}
                            {/*        <span className="notify"></span>*/}
                            {/*        <span className="heartbeat"></span>*/}
                            {/*    </a>*/}
                            {/*    <ul className="dropdown-menu pullDown">*/}
                            {/*        <li className="header">NOTIFICATIONS</li>*/}

                            {/*        <li className="footer">*/}
                            {/*            <a href="#" onClick={()=> {*/}
                            {/*                return false;*/}
                            {/*            }}>View All Notifications</a>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                            <li className="dropdown user_profile" style={{ marginRight : 10 }}>
                                <div className="dropdown-toggle" data-bs-toggle="dropdown">
                                    <img src="assets/images/user.png" alt="user" />
                                </div>
                                <ul className="dropdown-menu pullDown">
                                    <li className="body">
                                        <ul className="user_dw_menu">
                                            {/*<li>*/}
                                            {/*    <a href="#" onClick={()=> {*/}
                                            {/*        return false;*/}
                                            {/*    }}>*/}
                                            {/*        <i className="material-icons">person</i>Profile*/}
                                            {/*    </a>*/}
                                            {/*</li>*/}
                                            <li>
                                                <a href="#" onClick={()=> {
                                                    Logout();
                                                }}>
                                                    <i className="material-icons">power_settings_new</i>Logout
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}
export default Header;
