import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import $ from "jquery";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import {File} from "react-feather";

export default function GTE() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    const [startDate,setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');

    useEffect(() => {
        LoadData();
    }, [])

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "GetGTE");
        formdata.append("branch", localStorage.getItem("Branch"));
        formdata.append("role", localStorage.getItem("Role"));
        formdata.append("user", localStorage.getItem("UserId"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterData=(e)=>{
        let val = e.target.value.toLowerCase();
        if (val) {
            const filter = filterRecord.filter((d) => {
                return d.LeadNo.toLowerCase().indexOf(val) > -1 || d.Comment.toLowerCase().indexOf(val) > -1 || d.Status.toLowerCase().indexOf(val) > -1 || d.InterviewDate.toLowerCase().indexOf(val) > -1
            })
            console.log(filter);
            setCustomerData(filter);
        }
        else
        {
            setCustomerData(filterRecord);
        }
    }

    function filterDateRecord() {
        const filter = filterRecord.filter((d) => {
            return d.Date >= startDate && d.Date <= endDate
        })
        console.log(filter);
        setCustomerData(filter);
    }

    return (
        <section className="content">
            <Card header={() => {
                return (
                    <div className="row justify-content-end gap-4 mt-4">
                        <Button onClick={() => history.push('/add-gte')} label="Add GTE"
                                icon="pi pi-plus"
                                style={{width: "auto"}}/>
                    </div>
                )
            }
            }
                  title={() => {
                      return (
                          <>
                              <button onClick={()=> history.go(-1) } style={{ border : "none",outline : "none",background : "transparent" }}><i className={'fas fa-arrow-left'}></i></button>&nbsp;&nbsp;&nbsp;
                              <span>GTE</span>
                              <div className="row justify-content-between">
                                  <div className="col-md-3 mt-1">
                                      <div className="form-group">
                                          <InputText type={'search'} onChange={(e) => filterData(e)}
                                                     placeholder={'Keyword Search...'}/>
                                      </div>
                                  </div>
                                  <div className="col-md-7 mt-1">
                                      <div className="row">
                                          <div className="col-md-4 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'} onChange={(e) => setStartDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-4 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'} onChange={(e) => setEndDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-3 mt-1">
                                              <div className="form-group">
                                                  <button onClick={filterDateRecord} className={'btn btn-primary form-control'}>Search</button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </>
                      )
                  }}
                  className="card">
                <Toast ref={toast}/>
                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No Applications Found !'} paginator
                           rows={10}
                           onRowClick={(p)=> {
                               let id = customerData[p.index].id;
                               history.push("/add-gte?id="+id);
                           }}
                           rowClassName={'cursor-pointer'}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}>
                    <Column field="LeadNo" header="File No" style={{minWidth: '10rem'}}/>
                    <Column field="Date" header="Date" style={{minWidth: '10rem'}}/>
                    <Column header="Doc1" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc1) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc1} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc2" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc2) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc2} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc3" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc3) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc3} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc4" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc4) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc4} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                    }}/>
                    <Column header="Doc5" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc5) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc5} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc6" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc6) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc6} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc7" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc7) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc7} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc8" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc8) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc8} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc9" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc9) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc9} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc10" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc10) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc10} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc11" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc11) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc11} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc12" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc12) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc12} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc13" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc13) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc13} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc14" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc14) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc14} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Doc15" style={{minWidth: '4rem'}} body={(p) => {
                        if (p.Doc15) {
                            return (
                                <a href={ImageLink + "Visa/" + p.Doc15} target={'_blank'}>
                                    <File/>
                                </a>
                            )
                        }
                        else
                        {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column header="Edit" style={{minWidth: '6rem'}} body={(p) => {
                        return (
                            <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                                history.push("/add-gte?id=" + p.id);
                            }}/>
                        )
                    }}/>
                    <Column header="Delete" style={{minWidth: '6rem'}} body={(p) => {
                        const acceptDelete = async () => {
                            var myHeaders = new Headers();
                            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                            var formdata = new FormData();
                            formdata.append("page", "DeleteVisa");
                            formdata.append("id", p.id);
                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdata,
                                redirect: 'follow'
                            };

                            await fetch(`${AppLink}Leads.php`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    console.log(result);
                                    setLoading(false);
                                    if (result.status == "1") {
                                        // let Data = result.Data;
                                        LoadData();
                                        showSuccess("Record Deleted Successfully");
                                    } else {
                                        showError();
                                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                                    }
                                })
                                .catch(error => console.log('error', error));
                        }
                        if (localStorage.getItem("Role") !== "Operator" && localStorage.getItem("Role") !== "Counselor") {
                            return (
                                <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                    confirmPopup({
                                        target: event.currentTarget,
                                        message: 'Do you want to delete this record?',
                                        icon: 'pi pi-info-circle',
                                        acceptClassName: 'p-button-danger',
                                        accept() {
                                            acceptDelete()
                                        },
                                        reject() {
                                            console.log("Reject");
                                        }
                                    });


                                }}/>
                            )
                        }
                        else
                        {
                            <></>
                        }
                    }}/>
                </DataTable>
            </Card>
        </section>
    );
}
