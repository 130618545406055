import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import Header from "../components/Header";
import Menu from "../components/Menu";
import {useHistory} from 'react-router-dom';
import {Dropdown} from "primereact/dropdown";

export default function AddLeads() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [leadData, setLeadData] = useState([]);
    const [name, setName] = useState('');
    const [leadNo, setLeadNo] = useState('');
    const [fname, setFname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDOB] = useState('');
    const [school, setSchool] = useState('');
    const [college, setCollege] = useState('');
    const [bachelor, setBachelor] = useState('');
    const [master, setMaster] = useState('');
    const [address, setAddress] = useState('');
    const [test, setTest] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileNo, setFileNo] = useState('');
    const [payment, setPayment] = useState('');
    const [country, setCountry] = useState('');
    const [assignTo, setAssignTo] = useState("");
    const [status, setStatus] = useState("");
    const [usersData, setUsersData] = useState([]);
    const [branch, setBranch] = useState('');
    const [comment, setComment] = useState("");
    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);
        LoadLeadNo();
        LoadUsers();
        if (res.id) {
            LoadLeadDetail(res.id);
        }

    }, []);

    async function LoadUsers() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadUsers");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    setUsersData(result.data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadLeadDetail(id) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadLead");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setLeadData(Data);
                    Data = Data[0];
                    setLeadNo(Data.LeadNo);
                    setName(Data.Name);
                    setFname(Data["Father Name"]);
                    setEmail(Data.Email);
                    setPhone(Data.Phone);
                    setSchool(Data.School);
                    setTest(Data.Test);
                    setFileNo(Data.FileNo);
                    setCountry(Data.Country);
                    setAssignTo(Data.AssignTo);
                    setStatus(Data.Status);
                    setPayment(Data.VoucherNo)
                    setBranch(Data.Branch)
                    setComment(Data.Comment)
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadLeadNo() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadLeadNo");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    setLeadNo(result.LeadNo);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("leadno", leadNo);
        formdata.append("CreatedBy", localStorage.getItem("UserId"));
        if (leadData.length > 0) {
            formdata.append("page", "UpdateLead");
            formdata.append("id", leadData[0].id);
        } else {
            formdata.append("page", "SaveLead");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    // let Data = result.Data;
                    $('#Form').trigger('reset');
                    setName("");
                    setFname("");
                    setEmail("");
                    setPhone("");
                    setSchool("");
                    setTest("");
                    setFileNo("");
                    setCountry("");
                    setAssignTo("");
                    setStatus("");
                    setPayment("");
                    setBranch("")
                    setComment("");
                    showSuccess(leadData.length > 0 ? "Record Updated Successfully" : "Record Saved Successfully");
                    LoadLeadNo();

                    setLeadData([]);
                    setTimeout(()=>{
                        navigate.go(-1)
                    },1000)
                    // setCustomerData(Data);
                } else if (result.status == "2") {
                    toast.current.show({severity: 'warn', summary: 'Duplicate Data !', detail: "File No Already Exists !", life: 3000});
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Add Lead</span>
                        </>
                    )
                }} className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Lead No <code>Auto Generated</code></label>
                            <InputText id="LeadNo" value={leadNo} disabled={true}
                                       required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="Name">Name </label>
                            <InputText id="Name" value={name} onChange={(e) => setName(e.target.value)} name='name'
                                       placeholder={'Enter Name'} autoFocus={true} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Father Name </label>
                            <InputText id="FatherName" value={fname} onChange={(e) => setFname(e.target.value)}
                                       name='fathername' placeholder={'Enter Father Name'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Email Address </label>
                            <InputText keyfilter={'email'} value={email} onChange={(e) => setEmail(e.target.value)}
                                       id="Email" name='email' placeholder={'Enter Email Address'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Phone No </label>
                            <InputText keyfilter={'num'} value={phone} onChange={(e) => setPhone(e.target.value)}
                                       id="PhoneNo" name='phone' placeholder={'Enter Phone No'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Qualification</label>
                            <InputText id="School" value={school} onChange={(e) => setSchool(e.target.value)}
                                       placeholder={'Enter Qualification'} name='school' required={false}/>
                        </div>
                        {/*<div className="col-md-6">*/}
                        {/*    <label>Date of birth </label>*/}
                        {/*    <InputText id="DOB" value={dob} onChange={(e) => setDOB(e.target.value)}*/}
                        {/*               placeholder={'DD/MM/YYYY'} name='dob' required={true}/>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <label>School Qualification</label>*/}
                        {/*    <InputText id="School" value={school} onChange={(e) => setSchool(e.target.value)}*/}
                        {/*               placeholder={'Enter Degree'} name='school' required={false}/>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <label>College Qualification</label>*/}
                        {/*    <InputText id="College" value={college} onChange={(e) => setCollege(e.target.value)}*/}
                        {/*               placeholder={'Enter Degree'} name='college' required={false}/>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <label>Bachelor's Qualification</label>*/}
                        {/*    <InputText id="Bachelor" value={bachelor} onChange={(e) => setBachelor(e.target.value)}*/}
                        {/*               placeholder={'Enter Degree'} name='bachelor' required={false}/>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <label>Master Qualification</label>*/}
                        {/*    <InputText id="Master" value={master} onChange={(e) => setMaster(e.target.value)}*/}
                        {/*               placeholder={'Enter Degree'} name='master' required={false}/>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <label>Address </label>*/}
                        {/*    <InputText id="Address" value={address} onChange={(e) => setAddress(e.target.value)}*/}
                        {/*               placeholder={'Enter Permanent Address'} name='address' required={true}/>*/}
                        {/*</div>*/}
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>English Test </label>
                                {/*<InputText id="Test" value={test} onChange={(e) => setTest(e.target.value)}*/}
                                {/*           placeholder={'Enter Test Detail'} name='test' required={true}/>*/}
                                <Dropdown name={'test'} value={test} onChange={(e) => setTest(e.target.value)}
                                          options={[
                                              {
                                                  name: "IELTS",
                                                  value: "IELTS"
                                              },
                                              {
                                                  name: "PTE",
                                                  value: "PTE"
                                              },
                                              {
                                                  name: "Language Cert",
                                                  value: "Language Cert"
                                              },
                                              {
                                                  name: "Other",
                                                  value: "Other"
                                              },
                                              {
                                                  name: "Pending",
                                                  value: "Pending"
                                              }
                                          ]} optionLabel="name"
                                          placeholder="Select Test" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Payment / Voucher No </label>
                            <InputText id="payment" value={payment} onChange={(e) => setPayment(e.target.value)}
                                       placeholder={'Enter Payment/Voucher No'} name='payment' required={true}/>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Upload Receipt </label>
                                <InputText id="upload" type={'file'} name='upload' className={'form-control'}/>
                            </div>
                        </div>
                        {/*<div className="col-md-6">*/}
                        {/*    <label>File Name </label>*/}
                        {/*    <InputText id="FileName" value={fileName} onChange={(e) => setFileName(e.target.value)}*/}
                        {/*               placeholder={'Enter File Name'} name='filename' required={true}/>*/}
                        {/*</div>*/}
                        <div className="col-md-6">
                            <label>File No </label>
                            <InputText id="FileNo" value={fileNo} onChange={(e) => setFileNo(e.target.value)}
                                       placeholder={'Enter File No'} name='fileno' required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Country Preferred </label>
                            <InputText id="Country" value={country} onChange={(e) => setCountry(e.target.value)}
                                       placeholder={'Enter Country Name'} name='country' required={true}/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Assign To </label>
                                <Dropdown filter name={'assignTo'} value={assignTo} onChange={(e) => setAssignTo(e.value)}
                                          options={usersData} optionLabel="name"
                                          placeholder="Select Assign To" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status</label>
                                <Dropdown name={'status'} value={status} onChange={(e) => setStatus(e.value)}
                                          options={[
                                              {name: "Pending", value: "Pending"},
                                              {name: "Processing", value: "Processing"},
                                              {name: "Rejected", value: "Rejected"},
                                              {name: "Completed", value: "Completed"}
                                          ]} optionLabel="name"
                                          placeholder="Select Status" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Branch <code>*</code></label>
                                <Dropdown name={'branch'} value={branch} onChange={(e) => setBranch(e.value)} options={[
                                    {
                                        name: "Multan",
                                        value: "Multan"
                                    },
                                    {
                                        name: "Gujranwala",
                                        value: "Gujranwala"
                                    },
                                    {
                                        name: "Sahiwal",
                                        value: "Sahiwal"
                                    },
                                    {
                                        name: "Bahawalpur",
                                        value: "Bahawalpur"
                                    }
                                ]} optionLabel="name"
                                          placeholder="Select a Branch" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comment</label>
                                <textarea value={comment} onChange={(e) => setComment(e.target.value)} name="comment"
                                          id="comment" rows="5" className={'form-control'}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {
                            leadData.length > 0 ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
