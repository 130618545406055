import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink from "../components/Link";
import {Toast} from "primereact/toast";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';

export default function UsersList() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [customerData, setCustomerData] = useState([]);
    useEffect(() => {
        LoadData();
    }, [])

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadUsers");
        formdata.append("branch", localStorage.getItem("Branch"));
        formdata.append("role", localStorage.getItem("Role"));
        formdata.append("user", localStorage.getItem("UserId"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}users.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card header={() => {
                return (
                    <div className="row justify-content-end gap-4 mt-4">
                        <Button onClick={() => history.push('/add-user')} label="Add User"
                                icon="pi pi-plus"
                                style={{width: "auto"}}/>
                    </div>
                )
            }
            } title={"Users List"} className="card">
                <Toast ref={toast}/>
                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No User Found !'} paginator
                           rows={10}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}>
                    <Column field="UserName" header="User Name" style={{minWidth: '12rem'}}/>
                    <Column field="Email" header="Email" style={{minWidth: '12rem'}}/>
                    <Column field="Password" header="Password" style={{minWidth: '12rem'}}/>
                    <Column field="Role" header="Role" style={{minWidth: '8rem'}}/>
                    <Column field="Branch" header="City" style={{minWidth: '8rem'}}/>
                    <Column header="Edit" style={{minWidth: '6rem'}} body={(p) => {
                        return (
                            <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                                history.push("/add-user?id=" + p.id);
                            }}/>
                        )
                    }}/>
                    <Column header="Delete" style={{minWidth: '6rem'}} body={(p) => {
                        const acceptDelete = async () => {
                            var myHeaders = new Headers();
                            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                            var formdata = new FormData();
                            formdata.append("page", "DeleteUser");
                            formdata.append("id", p.id);
                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdata,
                                redirect: 'follow'
                            };

                            await fetch(`${AppLink}users.php`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    console.log(result);
                                    setLoading(false);
                                    if (result.status == "1") {
                                        // let Data = result.Data;
                                        LoadData();
                                        showSuccess("Record Deleted Successfully");
                                    } else {
                                        showError();
                                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                                    }
                                })
                                .catch(error => console.log('error', error));
                        }
                        return (
                            <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                confirmPopup({
                                    target: event.currentTarget,
                                    message: 'Do you want to delete this record?',
                                    icon: 'pi pi-info-circle',
                                    acceptClassName: 'p-button-danger',
                                    accept() {
                                        acceptDelete()
                                    },
                                    reject() {
                                        console.log("Reject");
                                    }
                                });


                            }}/>
                        )
                    }}/>
                </DataTable>
            </Card>
        </section>
    );
}
