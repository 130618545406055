import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Card} from 'primereact/card';
import {Checkbox} from 'primereact/checkbox';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import {useHistory} from "react-router-dom";

export default function AddStudent() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [disability, setDisability] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [leadData, setLeadData] = useState([]);
    const [name, setName] = useState('');
    const [fname, setFname] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [snic, setSnic] = useState('');
    const [dob, setDob] = useState('');
    const [address, setAddress] = useState('');
    const [ename, setEname] = useState('');
    const [relationship, setRelationship] = useState('');
    const [ephone, setEphone] = useState('');
    const [enic, setEnic] = useState('');
    const [eaddress, setEaddress] = useState('');
    const [year, setYear] = useState('');
    const [institute, setInstitute] = useState('');
    const [subject, setSubject] = useState('');
    const [marks, setMarks] = useState('');
    const [grade, setGrade] = useState('');
    const [degree, setDegree] = useState('');
    const [type, setType] = useState('');
    const [date, setDate] = useState('');
    const [financial, setFinancial] = useState('');
    const [batch, setBatch] = useState('');
    const [teacher, setTeacher] = useState('');
    const [fee, setFee] = useState('');
    const [remaining, setRemaining] = useState('');
    const [branch, setBranch] = useState('');
    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);
        setType(res.test);
        if (res.id) {
            LoadLeadDetail(res.id);
        }
    }, []);

    async function LoadLeadDetail(id) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadStudent");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}users.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setLeadData(Data);
                    Data = Data[0];
                    setName(Data.Name);
                    setFname(Data.FatherName);
                    setGender(Data.Gender);
                    setPhone(Data.Phone);
                    setSnic(Data.NIC);
                    setDob(Data.DOB);
                    setAddress(Data.Address);
                    setDisability(Data.Disability == "true" ? true : false);
                    setRegistered(Data.Registered == "true" ? true : false);
                    setEname(Data.EName);
                    setRelationship(Data.Relationship);
                    setEphone(Data.EPhone);
                    setEnic(Data.ENIC);
                    setEaddress(Data.EAddress);
                    setYear(Data.Year);
                    setInstitute(Data.Institute);
                    setSubject(Data.Subject);
                    setMarks(Data.Marks);
                    setGrade(Data.Grade);
                    setDegree(Data.Degree);
                    setEmail(Data.Email);
                    setDate(Data.RegistrationDate);
                    setFinancial(Data.Financial);
                    setBatch(Data.Batch);
                    setTeacher(Data.Teacher);
                    setRemaining(Data.Remaining);
                    setBranch(Data.Branch);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("CreatedBy", localStorage.getItem("UserId"));
        formdata.append("registered", registered.toString());
        formdata.append("disability", disability.toString());
        formdata.append("type", type.toString());
        if (leadData.length > 0) {
            formdata.append("page", "UpdateStudent");
            formdata.append("id", leadData[0].id);
        } else {
            formdata.append("page", "SaveStudent");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}users.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    $('#Form').trigger('reset');
                    setName("");
                    setFname("");
                    setGender("");
                    setPhone("");
                    setSnic("");
                    setDob("");
                    setAddress("");
                    setDisability(false);
                    setRegistered(false);
                    setEname("");
                    setRelationship("");
                    setEphone("");
                    setEnic("");
                    setEaddress("");
                    setYear("");
                    setInstitute("");
                    setSubject("");
                    setMarks("");
                    setGrade("");
                    setDegree("");
                    setEmail("");
                    setDate("");
                    setFinancial("");
                    setBatch("")
                    setFee("")
                    setRemaining("")
                    setBranch("");
                    setTeacher("")
                    showSuccess(leadData.length > 0 ? "Record Updated Successfully" : "Record Saved Successfully");
                    setLeadData([]);
                    setTimeout(()=>{
                        navigate.go(-1)
                    },1000)
                    // setCustomerData(Data);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Add Student</span>
                        </>
                    )
                }}
                className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="Name">Student Name <code>*</code></label>
                            <InputText id="Name" value={name} onChange={(e) => setName(e.target.value)} name='name'
                                       placeholder={'Enter Student Name'} autoFocus={true} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Father Name <code>*</code></label>
                            <InputText value={fname} onChange={(e) => setFname(e.target.value)} name='fname'
                                       placeholder={'Enter Father Name'} required={true}/>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Student Sex/Gender <code>*</code></label>
                                <Dropdown name={'sex'} value={gender} onChange={(e) => setGender(e.value)} options={[
                                    {
                                        name: "Male",
                                        value: "Male"
                                    },
                                    {
                                        name: "Fe-Male",
                                        value: "FeMale"
                                    }
                                ]} optionLabel="name"
                                          placeholder="Select a Sex" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Email Address <code>*</code></label>
                            <InputText id="Email" value={email} onChange={(e) => setEmail(e.target.value)} name='email'
                                       placeholder={'Enter Email Address'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Phone No <code>*</code></label>
                            <InputText value={phone} onChange={(e) => setPhone(e.target.value)}
                                       name='phone'
                                       placeholder={'Enter Phone No'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>N.I.C No <code>*</code></label>
                            <InputText value={snic} onChange={(e) => setSnic(e.target.value)}
                                       name='snic'
                                       placeholder={'Enter N.I.C No'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>DOB <code>*</code></label>
                            <InputText value={dob} onChange={(e) => setDob(e.target.value)}
                                       name='dob'
                                       placeholder={'DD/MM/YYYY'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Address <code>*</code></label>
                            <InputText value={address} onChange={(e) => setAddress(e.target.value)}
                                       name='address'
                                       placeholder={'.....'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Financial Record <code>*</code></label>
                            <InputText value={financial} onChange={(e) => setFinancial(e.target.value)}
                                       name='financial'
                                       placeholder={'.....'}/>
                        </div>
                        <div className="col-md-6">
                            <label>Registration Date <code>*</code></label>
                            <InputText value={date} onChange={(e) => setDate(e.target.value)}
                                       name='date'
                                       type={'date'}
                                       placeholder={'.....'}/>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <label htmlFor="ingredient1" className="ml-2">Do you have a disability or ongoing medical
                            consideration which will require to seek special assistance from the Centre e.g.
                            Listening/ Visual impairment mobility ? <Checkbox inputId="ingredient1" name="disability"
                                                                              value={disability}
                                                                              onChange={(e) => setDisability(!e.target.value)}
                                                                              checked={disability}
                            /> </label>

                    </div>
                    <div className="row mt-2">
                        <label htmlFor="ingredient2" className="ml-2">Have you previously enrolled at Austrailian
                            Education TOFEL/IELETS Center ? <Checkbox inputId="ingredient2" name="registered"
                                                                      value={registered}
                                                                      onChange={(e) => setRegistered(!e.target.value)}
                                                                      checked={registered}
                            /> </label>
                    </div>
                    <div className="row mt-4">
                        <p><b>Person to be notified in case of emergency</b></p>
                        <div className="col-md-6">
                            <label>Name <code>*</code></label>
                            <InputText value={ename} onChange={(e) => setEname(e.target.value)} name='ename'
                                       placeholder={'Enter Name'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Relationship <code>*</code></label>
                            <InputText value={relationship} onChange={(e) => setRelationship(e.target.value)}
                                       name='relationship'
                                       placeholder={'Enter Relationship'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Phone <code>*</code></label>
                            <InputText value={ephone} onChange={(e) => setEphone(e.target.value)} name='ephone'
                                       placeholder={'Enter Phone No'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>N.I.C No <code>*</code></label>
                            <InputText value={enic} onChange={(e) => setEnic(e.target.value)} name='enic'
                                       placeholder={'Enter N.I.C No'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Address <code>*</code></label>
                            <InputText value={eaddress} onChange={(e) => setEaddress(e.target.value)} name='eaddress'
                                       placeholder={'Enter Address'} required={true}/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <p><b>Recent Qualification</b></p>
                        <div className="col-md-6">
                            <label>Year <code>*</code></label>
                            <InputText value={year} onChange={(e) => setYear(e.target.value)} name='year'
                                       placeholder={'Enter Year'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Institute <code>*</code></label>
                            <InputText value={institute} onChange={(e) => setInstitute(e.target.value)} name='institute'
                                       placeholder={'Enter Institute'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Major Subject <code>*</code></label>
                            <InputText value={subject} onChange={(e) => setSubject(e.target.value)} name='subject'
                                       placeholder={'Enter Subject'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Marks <code>*</code></label>
                            <InputText value={marks} onChange={(e) => setMarks(e.target.value)} name='marks'
                                       placeholder={'Enter Marks'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Grade/Division <code>*</code></label>
                            <InputText value={grade} onChange={(e) => setGrade(e.target.value)} name='grade'
                                       placeholder={'Enter Grade/Division'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Degree/Diploma <code>*</code></label>
                            <InputText value={degree} onChange={(e) => setDegree(e.target.value)} name='degree'
                                       placeholder={'Enter Degree/Diploma'} required={true}/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <p><b>Office Use</b></p>
                        {
                            function () {
                                if (type == "ielts") {
                                    return (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Batch <code>*</code></label>
                                                <Dropdown name={'batch'} value={batch}
                                                          onChange={(e) => setBatch(e.value)}
                                                          options={[
                                                              {name: "10-12", value: "10-12"},
                                                              {name: "3-5", value: "3-5"},
                                                              {name: "6-8", value: "6-8"},
                                                          ]} optionLabel="name"
                                                          placeholder="Select Batch" className='form-control'/>
                                            </div>
                                        </div>
                                    )
                                } else if (type == "pte") {
                                    return (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Batch <code>*</code></label>
                                                <Dropdown name={'batch'} value={batch}
                                                          onChange={(e) => setBatch(e.value)}
                                                          options={[
                                                              {name: "10-12", value: "10-12"},
                                                              {name: "12-2", value: "12-02"},
                                                              {name: "3-5", value: "3-5"},
                                                              {name: "6-8", value: "6-8"},
                                                          ]} optionLabel="name"
                                                          placeholder="Select Batch" className='form-control'/>
                                            </div>
                                        </div>
                                    )
                                } else if (type == "spoken") {
                                    return (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Batch <code>*</code></label>
                                                <Dropdown name={'batch'} value={batch}
                                                          onChange={(e) => setBatch(e.value)}
                                                          options={[
                                                              {name: "10-12", value: "10-12"},
                                                              {name: "3-5", value: "3-5"},
                                                              {name: "2-4", value: "2-4"},
                                                          ]} optionLabel="name"
                                                          placeholder="Select Batch" className='form-control'/>
                                            </div>
                                        </div>
                                    )
                                } else if (type == "tofel") {
                                    return (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Batch <code>*</code></label>
                                                <Dropdown name={'batch'} value={batch}
                                                          onChange={(e) => setBatch(e.value)}
                                                          options={[
                                                              {name: "10-12", value: "10-12"},
                                                              {name: "3-5", value: "3-5"},
                                                          ]} optionLabel="name"
                                                          placeholder="Select Batch" className='form-control'/>
                                            </div>
                                        </div>
                                    )
                                }
                            }.call(this)
                        }
                        <div className="col-md-6">
                            <label>Instructor <code>*</code></label>
                            <InputText value={teacher} onChange={(e) => setTeacher(e.target.value)} name='teacher'
                                       placeholder={'Enter Teacher Name'}/>
                        </div>

                        {
                            leadData.length > 0 ? null :
                                <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Fee <code>*</code></label>
                                            <InputText value={fee} onChange={(e) => setFee(e.target.value)} name='fee'
                                                       placeholder={'Enter Fee'}/>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Fee Slip <code>*</code></label>
                                                <InputText name='upload' type={'file'} className={'form-control'}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Test Result <code>*</code></label>
                                        <InputText name='result'
                                                   placeholder={'Enter Test Result'}/>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Result Document <code>*</code></label>
                                            <InputText name='document' type={'file'} className={'form-control'}/>
                                        </div>
                                    </div>
                                </>
                        }
                        <div className="col-md-6">
                            <label>Remaining Fee <code>*</code></label>
                            <InputText type={'number'} value={remaining} onChange={(e) => setRemaining(e.target.value)} name='remaining'
                                       placeholder={'Enter Remaining Fee'}/>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Branch <code>*</code></label>
                                <Dropdown name={'branch'} value={branch} onChange={(e) => setBranch(e.value)} options={[
                                    {
                                        name : "Multan",
                                        value : "Multan"
                                    },
                                    {
                                        name : "Gujranwala",
                                        value : "Gujranwala"
                                    },
                                    {
                                        name : "Sahiwal",
                                        value : "Sahiwal"
                                    },
                                    {
                                        name : "Bahawalpur",
                                        value : "Bahawalpur"
                                    }
                                ]} optionLabel="name"
                                          placeholder="Select a Branch" className='form-control' />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {
                            leadData.length > 0 ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
