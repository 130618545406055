import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink from "../components/Link";
import {Toast} from "primereact/toast";
import $ from "jquery";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import Header from "../components/Header";
import Menu from "../components/Menu";

export default function Task() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    useEffect(() => {
        LoadData();
    }, [])

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "GetTasks");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterData=(e)=>{
        let val = e.target.value.toLowerCase();
        if (val) {
            const filter = filterRecord.filter((d) => {
                return d.FileNo.toLowerCase().indexOf(val) > -1 || d.LeadNo.toLowerCase().indexOf(val) > -1 || d.Name.toLowerCase().indexOf(val) > -1
            })
            console.log(filter);
            setCustomerData(filter);
        }
        else
        {
            setCustomerData(filterRecord);
        }
    }

    return (
        <section className="content">
            <Card header={() => {
                return (
                    <div className="row justify-content-end gap-4 mt-4">
                        <Button onClick={() => history.push('/add-task')} label="Add Task"
                                icon="pi pi-plus"
                                style={{width: "auto"}}/>
                    </div>
                )
            }
            }
                  title={() => {
                      return (
                          <>
                              <span>Tasks List</span>
                              <div className="col-md-3 mt-1">
                                  <div className="form-group">
                                      <InputText type={'search'} onChange={(e)=> filterData(e)} placeholder={'Keyword Search...'}/>
                                  </div>
                              </div>
                          </>
                      )
                  }}
                  className="card">
                <Toast ref={toast}/>
                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No Task Found !'} paginator
                           rows={10}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}>
                    <Column field="LeadNo" header="Lead No" style={{minWidth: '10rem'}}/>
                    <Column field="FileNo" header="File No" style={{minWidth: '10rem'}}/>
                    <Column field="Name" header="Name" style={{minWidth: '12rem'}}/>
                    <Column field="Father Name" header="Father Name" style={{minWidth: '12rem'}}/>
                    <Column field="Phone" header="Phone No" style={{minWidth: '10rem'}}/>
                    <Column field="Email" header="Email Address" style={{minWidth: '12rem'}}/>
                    <Column field="Password" header="Password" style={{minWidth: '12rem'}}/>
                    <Column field="ParentType" header="Parent Type" style={{minWidth: '12rem'}}/>
                    <Column field="CallResponse" header="Call Response" style={{minWidth: '12rem'}}/>
                    <Column field="StartDate" header="Start Date" style={{minWidth: '12rem'}}/>
                    <Column field="Priority" header="Priority" style={{minWidth: '12rem'}}/>
                    <Column field="Type" header="Type" style={{minWidth: '12rem'}}/>
                    <Column field="Department" header="Department" style={{minWidth: '12rem'}}/>
                    <Column field="DueDate" header="Due Date" style={{minWidth: '12rem'}}/>
                    <Column field="Related" header="Related" style={{minWidth: '12rem'}}/>
                    <Column field="DateModified" header="Date Modified" style={{minWidth: '12rem'}}/>
                    <Column field="DateCreated" header="Date Created" style={{minWidth: '12rem'}}/>
                    <Column field="AssignTo" header="AssignTo" style={{minWidth: '12rem'}}/>
                    <Column field="FileNo" header="FileNo" style={{minWidth: '12rem'}}/>
                    <Column field="RegisteredDate" header="Registered Date" style={{minWidth: '12rem'}}/>
                    <Column field="ForwardTo" header="ForwardTo" style={{minWidth: '12rem'}}/>
                    <Column field="CaseLine" header="CaseLine" style={{minWidth: '12rem'}}/>
                    <Column field="Intake" header="Intake" style={{minWidth: '12rem'}}/>
                    <Column field="CreatedBy" header="Created By" style={{minWidth: '12rem'}}/>
                    <Column field="Branch" header="Branch" style={{minWidth: '12rem'}}/>
                    <Column header="Edit" style={{minWidth: '6rem'}} body={(p) => {
                        return (
                            <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                                history.push("/add-task?id=" + p.id);
                            }}/>
                        )
                    }}/>
                    <Column header="Delete" style={{minWidth: '6rem'}} body={(p) => {
                        const acceptDelete = async () => {
                            var myHeaders = new Headers();
                            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
                            var formdata = new FormData();
                            formdata.append("page", "DeleteTask");
                            formdata.append("id", p.id);
                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdata,
                                redirect: 'follow'
                            };

                            await fetch(`${AppLink}Leads.php`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    console.log(result);
                                    setLoading(false);
                                    if (result.status == "1") {
                                        // let Data = result.Data;
                                        LoadData();
                                        showSuccess("Record Deleted Successfully");
                                    } else {
                                        showError();
                                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                                    }
                                })
                                .catch(error => console.log('error', error));
                        }
                        return (
                            <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                                confirmPopup({
                                    target: event.currentTarget,
                                    message: 'Do you want to delete this record?',
                                    icon: 'pi pi-info-circle',
                                    acceptClassName: 'p-button-danger',
                                    accept() {
                                        acceptDelete()
                                    },
                                    reject() {
                                        console.log("Reject");
                                    }
                                });


                            }}/>
                        )
                    }}/>
                </DataTable>
            </Card>
        </section>
    );
}
