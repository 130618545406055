import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import $ from "jquery";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from "primereact/dropdown";

export default function LeadsList() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    //Leads
    const [leadData, setLeadData] = useState([]);
    const [updateId, setUpdateId] = useState('');
    const [name, setName] = useState('');
    const [leadNo, setLeadNo] = useState('');
    const [fname, setFname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [school, setSchool] = useState('');
    const [test, setTest] = useState('');
    const [fileNo, setFileNo] = useState('');
    const [payment, setPayment] = useState('');
    const [country, setCountry] = useState('');
    const [assignTo, setAssignTo] = useState("");
    const [status, setStatus] = useState("");
    const [usersData, setUsersData] = useState([]);
    const [comment, setComment] = useState('');
    //Expansion
    const [expandedRows, setExpandedRows] = useState(null);
    const [branch, setBranch] = useState('');
    const [isDate, setIsDate] = useState(false);

    useEffect(() => {
        LoadData();
        LoadUsers();
    }, [])

    async function LoadUsers() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadUsers");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    setUsersData(result.data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadLeadDetail(id) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadLead");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    Data = Data[0];
                    setLeadNo(Data.LeadNo);
                    setName(Data.Name);
                    setFname(Data["Father Name"]);
                    setEmail(Data.Email);
                    setPhone(Data.Phone);
                    setSchool(Data.School);
                    setTest(Data.Test);
                    setFileNo(Data.FileNo);
                    setCountry(Data.Country);
                    setAssignTo(Data.AssignTo);
                    setStatus(Data.Status);
                    setPayment(Data.VoucherNo)
                    setComment(Data.Comment);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("leadno", leadNo);
        formdata.append("CreatedBy", localStorage.getItem("UserId"));
        formdata.append("page", "UpdateLead");
        formdata.append("id", updateId);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    // let Data = result.Data;
                    $('#Form').trigger('reset');
                    setName("");
                    setFname("");
                    setEmail("");
                    setPhone("");
                    setSchool("");
                    setTest("");
                    setFileNo("");
                    setCountry("");
                    setAssignTo("");
                    setStatus("");
                    setPayment("");
                    LoadData();
                    showSuccess("Record Updated Successfully");
                    setLoading(false);
                    setVisible(false);

                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadLeads");
        formdata.append("branch", localStorage.getItem("Branch"));
        formdata.append("role", localStorage.getItem("Role"));
        formdata.append("user", localStorage.getItem("UserId"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    console.log(Data);
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterData = (e) => {
        let val = e.target.value.toLowerCase();
        if (val) {
            const filter = filterRecord.filter((d) => {
                return d.FileNo.toLowerCase().indexOf(val) > -1 || d.LeadNo.toLowerCase().indexOf(val) > -1 || d.Name.toLowerCase().indexOf(val) > -1
            })
            console.log(filter);
            setCustomerData(filter);
        } else {
            setCustomerData(filterRecord);
        }
    }


    const Del = () => {

        const acceptDelete = async () => {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
            var formdata = new FormData();
            formdata.append("page", "DeleteLead");
            formdata.append("id", updateId);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${AppLink}Leads.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    setLoading(false);
                    if (result.status == "1") {
                        // let Data = result.Data;
                        LoadData();
                        setVisible(false);
                        showSuccess("Record Deleted Successfully");
                    } else {
                        showError();
                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                    }
                })
                .catch(error => console.log('error', error));
        }
        if (localStorage.getItem("Role") !== "Operator" && localStorage.getItem("Role") !== "Counselor") {
            return (
                <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                    confirmPopup({
                        target: event.currentTarget,
                        message: 'Do you want to delete this record?',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept() {
                            acceptDelete()
                        },
                        reject() {
                            console.log("Reject");
                        }
                    });


                }}/>
            )
        } else {
            <></>
        }
    }

    const Dup = () => {
        const duplicateRecord = async () => {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
            var formdata = new FormData();
            formdata.append("page", "DuplicateLead");
            formdata.append("CreatedBy", localStorage.getItem("UserId"));
            formdata.append("id", updateId);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${AppLink}Leads.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    setLoading(false);
                    if (result.status == "1") {
                        // let Data = result.Data;
                        LoadData();
                        setVisible(false);
                        showSuccess("Record Duplicated Successfully");
                    } else {
                        showError();
                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                    }
                })
                .catch(error => console.log('error', error));
        }
        return (
            <Button icon={'pi pi-sync'} severity={'help'} onClick={async (event) => {
                confirmPopup({
                    target: event.currentTarget,
                    message: 'Do you want to duplicate this record?',
                    icon: 'pi pi-info-circle',
                    acceptClassName: 'p-button-info',
                    accept() {
                        duplicateRecord()
                    },
                    reject() {
                        console.log("Reject");
                    }
                });
            }}/>
        )
    }
    const Edit = () => {
        return (
            <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                history.push("/add-leads?id=" + updateId);
            }}/>
        )
    }


    const rowExpansionTemplate = (data) => {
        let arr = [data];
        return (
            <div className="p-3">
                <DataTable value={arr}>

                    <Column field="Country" header="Country Preferred" style={{minWidth: '10rem'}}/>


                    <Column field="Email" header="Email Address" style={{minWidth: '12rem'}}/>

                    <Column field="School" header="Qualification" style={{minWidth: '10rem'}}/>
                    <Column field="Test" header="English Test" style={{minWidth: '10rem'}}/>
                    <Column field="VoucherNo" header="Payment/Voucher No" style={{minWidth: '10rem'}}/>
                    <Column header="Receipt" style={{minWidth: '10rem'}} body={(p) => {
                        return (
                            <a href={ImageLink + p.Image} target={'_blank'}>
                                <img src={ImageLink + p.Image} style={{width: 80, height: 80, borderRadius: 4}}/>
                            </a>
                        )
                    }}/>
                    <Column field="AssignTo" header="Assigned To" style={{minWidth: '10rem'}}/>
                </DataTable>
            </div>
        );
    };

    function filterDateRecord() {
        if (startDate && endDate) {
            const filter = filterRecord.filter((d) => {
                return d.Date >= startDate && d.Date <= endDate
            })
            console.log(filter);
            setCustomerData(filter);
            setIsDate(true);
        } else {
            toast.current.show({
                severity: 'warn',
                summary: 'Date Empty !',
                detail: 'Please select From and To Date for filter record',
                life: 3000
            });
            setCustomerData(filterRecord);
            setIsDate(false);
        }
    }

    function filterRecordBranch(b) {
        if (b) {
            if (b !== "All") {
                if (isDate) {
                    const filter = customerData.filter((d) => {
                        return d.Branch.toLowerCase() === b.toString().toLowerCase()
                    })
                    console.log(filter);
                    setCustomerData(filter);
                }
                else
                {
                    const filter = filterRecord.filter((d) => {
                        return d.Branch.toLowerCase() === b.toString().toLowerCase()
                    })
                    console.log(filter);
                    setCustomerData(filter);
                }
            }
            else
            {
                setEndDate("")
                setStartDate("")
                setIsDate(false);
                setCustomerData(filterRecord);
            }
        }
        else if (isDate)
        {
            const filter = filterRecord.filter((d) => {
                return d.Date >= startDate && d.Date <= endDate
            })
            console.log(filter);
            setCustomerData(filter);
        }
        else
        {
            setCustomerData(filterRecord);
        }
    }

    return (
        <section className="content">
            <Card header={() => {
                return (
                    <div className="row justify-content-end gap-4 mt-4">
                        <Button onClick={() => history.push('/add-leads')} label="Add Leads"
                                icon="pi pi-plus"
                                style={{width: "auto"}}/>
                    </div>
                )
            }
            } title={() => {
                return (
                    <>
                        <button onClick={() => history.go(-1)}
                                style={{border: "none", outline: "none", background: "transparent"}}><i
                            className={'fas fa-arrow-left'}></i></button>
                        &nbsp;&nbsp;&nbsp;
                        <span>Leads List</span>
                        <div className="row justify-content-between">
                            <div className="col-md-3 mt-1">
                                <div className="form-group">
                                    <InputText type={'search'} onChange={(e) => filterData(e)}
                                               placeholder={'Keyword Search...'}/>
                                </div>
                            </div>
                            <div className="col-md-9 mt-1">
                                <div className="row">
                                    <div className="col-md-3 mt-1">
                                        <div className="form-group">
                                            <InputText type={'date'} value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-1">
                                        <div className="form-group">
                                            <InputText type={'date'} value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-md-2 mt-1">
                                        <div className="form-group">
                                            <button onClick={filterDateRecord} style={{marginTop: 10}}
                                                    className={'btn btn-primary form-control'}>Search
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        localStorage.getItem("Role") == "Admin" ?
                                            <div className="col-md-4 mt-1">
                                                <div className="form-group">
                                                    <Dropdown name={'branch'} value={branch} onChange={(e) => {
                                                        setBranch(e.value);
                                                        filterRecordBranch(e.value);
                                                    }} options={[
                                                        {
                                                            name: "--Select Branch--",
                                                            value: ""
                                                        },{
                                                            name: "Multan",
                                                            value: "Multan"
                                                        },
                                                        {
                                                            name: "Gujranwala",
                                                            value: "Gujranwala"
                                                        },
                                                        {
                                                            name: "Sahiwal",
                                                            value: "Sahiwal"
                                                        },
                                                        {
                                                            name: "Bahawalpur",
                                                            value: "Bahawalpur"
                                                        },
                                                        {
                                                            name: "All Branches",
                                                            value: "All"
                                                        }
                                                    ]} optionLabel="name"
                                                              placeholder="Select a Branch" className='form-control'/>
                                                </div>
                                            </div> : null
                                    }

                                </div>
                            </div>
                        </div>
                    </>
                )
            }} className="card">
                <Toast ref={toast}/>
                <Dialog header="Lead Detail" visible={visible} style={{width: '50vw'}} onHide={() => setVisible(false)}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Lead No <code>Auto Generated</code></label>
                            <InputText id="LeadNo" value={leadNo} disabled={true}
                                       required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="Name">Name <code>*</code></label>
                            <InputText id="Name" value={name} onChange={(e) => setName(e.target.value)} name='name'
                                       placeholder={'Enter Name'} disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Father Name <code>*</code></label>
                            <InputText id="FatherName" value={fname} onChange={(e) => setFname(e.target.value)}
                                       name='fathername' placeholder={'Enter Father Name'} required={true}
                                       disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Email Address <code>*</code></label>
                            <InputText keyfilter={'email'} value={email} onChange={(e) => setEmail(e.target.value)}
                                       id="Email" name='email' placeholder={'Enter Email Address'} required={true}
                                       disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Phone No <code>*</code></label>
                            <InputText keyfilter={'num'} value={phone} onChange={(e) => setPhone(e.target.value)}
                                       id="PhoneNo" name='phone' placeholder={'Enter Phone No'} required={true}
                                       disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Qualification</label>
                            <InputText id="School" value={school} onChange={(e) => setSchool(e.target.value)}
                                       placeholder={'Enter Qualification'} name='school' required={false}
                                       disabled={true}/>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>English Test <code>*</code></label>
                                <Dropdown name={'test'} value={test} disabled={true}
                                          onChange={(e) => setTest(e.target.value)}
                                          options={[
                                              {
                                                  name: "IELTS",
                                                  value: "IELTS"
                                              },
                                              {
                                                  name: "PTE",
                                                  value: "PTE"
                                              },
                                              {
                                                  name: "Language Cert",
                                                  value: "Language Cert"
                                              },
                                              {
                                                  name: "Other",
                                                  value: "Other"
                                              },
                                              {
                                                  name: "Pending",
                                                  value: "Pending"
                                              }
                                          ]} optionLabel="name"
                                          placeholder="Select Test" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Payment / Voucher No <code>*</code></label>
                            <InputText id="payment" value={payment} onChange={(e) => setPayment(e.target.value)}
                                       placeholder={'Enter Payment/Voucher No'} name='payment' required={true}
                                       disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>File No <code>*</code></label>
                            <InputText id="FileNo" value={fileNo} onChange={(e) => setFileNo(e.target.value)}
                                       placeholder={'Enter File No'} name='fileno' required={true} disabled={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Country Preferred <code>*</code></label>
                            <InputText id="Country" value={country} onChange={(e) => setCountry(e.target.value)}
                                       placeholder={'Enter Country Name'} name='country' required={true}
                                       disabled={true}/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Assign To <code>*</code></label>
                                <Dropdown name={'assignTo'} disabled={true} value={assignTo}
                                          onChange={(e) => setAssignTo(e.value)}
                                          options={usersData} optionLabel="name"
                                          placeholder="Select Assign To" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status</label>
                                <Dropdown name={'status'} disabled={true} value={status}
                                          onChange={(e) => setStatus(e.value)}
                                          options={[
                                              {name: "Pending", value: "Pending"},
                                              {name: "Processing", value: "Processing"},
                                              {name: "Rejected", value: "Rejected"},
                                              {name: "Completed", value: "Completed"}
                                          ]} optionLabel="name"
                                          placeholder="Select Status" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comment</label>
                                <textarea disabled={true} value={comment} onChange={(e) => setComment(e.target.value)}
                                          name="comment" id="comment" rows="5" className={'form-control'}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <Dup/>&nbsp;&nbsp;&nbsp;
                        <Edit/>&nbsp;&nbsp;&nbsp;
                        <Del/>&nbsp;&nbsp;&nbsp;
                    </div>
                </Dialog>
                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No Leads Found !'} paginator
                           rows={10}
                           onRowClick={async (p) => {
                               let id = customerData[p.index].id;
                               setUpdateId(id);
                               await LoadLeadDetail(id);
                               setVisible(true);
                           }}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}
                           expandedRows={expandedRows} onRowToggle={(e) => {
                    setExpandedRows(e.data);
                    console.log(e.data);
                }}
                           rowExpansionTemplate={rowExpansionTemplate}
                >
                    <Column expander={true} style={{width: '5rem'}}/>
                    <Column field="LeadNo" header="Lead No" style={{minWidth: '10rem'}}/>
                    <Column field="FileNo" header="File No" style={{minWidth: '10rem'}}/>
                    <Column field="Name" header="Name" style={{minWidth: '12rem'}}/>
                    <Column field="Father Name" header="Father Name" style={{minWidth: '12rem'}}/>
                    <Column field="Phone" header="Phone No" style={{minWidth: '10rem'}}/>
                    <Column header="Status" style={{minWidth: '10rem'}} body={(p) => {
                        if (p.Status !== "") {
                            return (
                                <div style={{
                                    backgroundColor: function () {
                                        if (p.Status == "Processing")
                                            return "#FFCC66"
                                        else if (p.Status == "Pending")
                                            return "#000"
                                        else if (p.Status == "Rejected")
                                            return "#ED9393"
                                        else if (p.Status == "Completed")
                                            return "#82CCB0"
                                    }.call(this),
                                    padding: "4px 10px",
                                    borderRadius: 5,
                                    textAlign: "center",
                                    color: "#fff"
                                }}>
                                    <span>{p.Status}</span>
                                </div>
                            )
                        } else {
                            return (
                                <></>
                            )
                        }
                    }}/>

                </DataTable>
            </Card>
        </section>
    );
}
