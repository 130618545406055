import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import $ from "jquery";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import Header from "../components/Header";
import Menu from "../components/Menu";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";

export default function Spoken() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    //Fields
    const [disability, setDisability] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [leadData, setLeadData] = useState([]);
    const [name, setName] = useState('');
    const [fname, setFname] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [snic, setSnic] = useState('');
    const [dob, setDob] = useState('');
    const [address, setAddress] = useState('');
    const [ename, setEname] = useState('');
    const [relationship, setRelationship] = useState('');
    const [ephone, setEphone] = useState('');
    const [enic, setEnic] = useState('');
    const [eaddress, setEaddress] = useState('');
    const [year, setYear] = useState('');
    const [institute, setInstitute] = useState('');
    const [subject, setSubject] = useState('');
    const [marks, setMarks] = useState('');
    const [grade, setGrade] = useState('');
    const [degree, setDegree] = useState('');
    const [type, setType] = useState('');
    const [date, setDate] = useState('');
    const [financial, setFinancial] = useState('');
    const [batch, setBatch] = useState('');
    const [teacher, setTeacher] = useState('');
    const [fee, setFee] = useState('');
    const [visible, setVisible] = useState(false);
    const [updateId, setUpdateId] = useState('');
    const [remaining, setRemaining] = useState('');

    useEffect(() => {
        LoadData();
    }, [])

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "LoadStudents");
        formdata.append("type", "spoken");
        formdata.append("branch", localStorage.getItem("Branch"));
        formdata.append("role", localStorage.getItem("Role"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}users.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }
    const filterData = (e) => {
        let val = e.target.value.toLowerCase();
        if (val) {
            const filter = filterRecord.filter((d) => {
                return d.Name.toLowerCase().indexOf(val) > -1 || d.Batch.toLowerCase().indexOf(val) > -1 || d.Teacher.toLowerCase().indexOf(val) > -1 || d.Phone.toLowerCase().indexOf(val) > -1 || d.NIC.toLowerCase().indexOf(val) > -1
            })
            console.log(filter);
            setCustomerData(filter);
        } else {
            setCustomerData(filterRecord);
        }
    }

    function filterDateRecord() {
        const filter = filterRecord.filter((d) => {
            return d.Date >= startDate && d.Date <= endDate
        })
        console.log(filter);
        setCustomerData(filter);
    }

    const Edit = () => {
        return (
            <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                history.push("/add-student?test=spoken&id=" + updateId);
            }}/>
        )
    }

    const Del = () => {
        const acceptDelete = async () => {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
            var formdata = new FormData();
            formdata.append("page", "DeleteStudent");
            formdata.append("id", updateId);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${AppLink}users.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    setLoading(false);
                    if (result.status == "1") {
                        // let Data = result.Data;
                        LoadData();
                        showSuccess("Record Deleted Successfully");
                    } else {
                        showError();
                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                    }
                })
                .catch(error => console.log('error', error));
        }
        if (localStorage.getItem("Role") !== "Operator" && localStorage.getItem("Role") !== "Counselor") {
            return (
                <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                    confirmPopup({
                        target: event.currentTarget,
                        message: 'Do you want to delete this record?',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept() {
                            acceptDelete()
                        },
                        reject() {
                            console.log("Reject");
                        }
                    });


                }}/>
            )
        }
        else
        {
            <></>
        }
    }

    return (
        <section className="content">
            <Card header={() => {
                return (
                    <div className="row justify-content-end gap-4 mt-4">
                        <Button onClick={() => history.push('/add-student?test=spoken')} label="Add Student"
                                icon="pi pi-plus"
                                style={{width: "auto"}}/>
                    </div>
                )
            }
            }
                  title={() => {
                      return (
                          <>
                              <button onClick={() => history.go(-1)}
                                      style={{border: "none", outline: "none", background: "transparent"}}><i
                                  className={'fas fa-arrow-left'}></i></button>
                              &nbsp;&nbsp;&nbsp;
                              <span>Spoken Students List</span>
                              <div className="row justify-content-between">
                                  <div className="col-md-3 mt-1">
                                      <div className="form-group">
                                          <InputText type={'search'} onChange={(e) => filterData(e)}
                                                     placeholder={'Keyword Search...'}/>
                                      </div>
                                  </div>
                                  <div className="col-md-7 mt-1">
                                      <div className="row">
                                          <div className="col-md-4 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'}
                                                             onChange={(e) => setStartDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-4 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'}
                                                             onChange={(e) => setEndDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-3 mt-1">
                                              <div className="form-group">
                                                  <button onClick={filterDateRecord}
                                                          className={'btn btn-primary form-control'}>Search
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </>
                      )
                  }}
                  className="card">
                <Toast ref={toast}/>
                <Dialog header="Student Detail" visible={visible} style={{width: '50vw'}}
                        onHide={() => setVisible(false)}>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="Name">Student Name <code>*</code></label>
                            <InputText disabled={true} id="Name" value={name} onChange={(e) => setName(e.target.value)}
                                       name='name'
                                       placeholder={'Enter Student Name'} autoFocus={true} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Father Name <code>*</code></label>
                            <InputText disabled={true} value={fname} onChange={(e) => setFname(e.target.value)}
                                       name='fname'
                                       placeholder={'Enter Father Name'} required={true}/>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Student Sex/Gender <code>*</code></label>
                                <Dropdown disabled={true} name={'sex'} value={gender}
                                          onChange={(e) => setGender(e.value)} options={[
                                    {
                                        name: "Male",
                                        value: "Male"
                                    },
                                    {
                                        name: "Fe-Male",
                                        value: "FeMale"
                                    }
                                ]} optionLabel="name"
                                          placeholder="Select a Sex" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Email Address <code>*</code></label>
                            <InputText disabled={true} id="Email" value={email}
                                       onChange={(e) => setEmail(e.target.value)} name='email'
                                       placeholder={'Enter Email Address'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Phone No <code>*</code></label>
                            <InputText disabled={true} value={phone} onChange={(e) => setPhone(e.target.value)}
                                       name='phone'
                                       placeholder={'Enter Phone No'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>N.I.C No <code>*</code></label>
                            <InputText disabled={true} value={snic} onChange={(e) => setSnic(e.target.value)}
                                       name='snic'
                                       placeholder={'Enter N.I.C No'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>DOB <code>*</code></label>
                            <InputText disabled={true} value={dob} onChange={(e) => setDob(e.target.value)}
                                       name='dob'
                                       placeholder={'DD/MM/YYYY'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Address <code>*</code></label>
                            <InputText disabled={true} value={address} onChange={(e) => setAddress(e.target.value)}
                                       name='address'
                                       placeholder={'.....'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Financial Record <code>*</code></label>
                            <InputText disabled={true} value={financial} onChange={(e) => setFinancial(e.target.value)}
                                       name='financial'
                                       placeholder={'.....'}/>
                        </div>
                        <div className="col-md-6">
                            <label>Registration Date <code>*</code></label>
                            <InputText disabled={true} value={date} onChange={(e) => setDate(e.target.value)}
                                       name='date'
                                       type={'date'}
                                       placeholder={'.....'}/>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <label htmlFor="ingredient1" className="ml-2">Do you have a disability or ongoing medical
                            consideration which will require to seek special assistance from the Centre e.g.
                            Listening/ Visual impairment mobility ? <Checkbox inputId="ingredient1" name="disability"
                                                                              value={disability} disabled={true}
                                                                              onChange={(e) => setDisability(!e.target.value)}
                                                                              checked={disability}
                            /> </label>

                    </div>
                    <div className="row mt-2">
                        <label htmlFor="ingredient2" className="ml-2">Have you previously enrolled at Austrailian
                            Education TOFEL/IELETS Center ? <Checkbox inputId="ingredient2" name="registered"
                                                                      value={registered} disabled={true}
                                                                      onChange={(e) => setRegistered(!e.target.value)}
                                                                      checked={registered}
                            /> </label>
                    </div>
                    <div className="row mt-4">
                        <p><b>Person to be notified in case of emergency</b></p>
                        <div className="col-md-6">
                            <label>Name <code>*</code></label>
                            <InputText disabled={true} value={ename} onChange={(e) => setEname(e.target.value)}
                                       name='ename'
                                       placeholder={'Enter Name'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Relationship <code>*</code></label>
                            <InputText disabled={true} value={relationship}
                                       onChange={(e) => setRelationship(e.target.value)}
                                       name='relationship'
                                       placeholder={'Enter Relationship'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Phone <code>*</code></label>
                            <InputText disabled={true} value={ephone} onChange={(e) => setEphone(e.target.value)}
                                       name='ephone'
                                       placeholder={'Enter Phone No'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>N.I.C No <code>*</code></label>
                            <InputText disabled={true} value={enic} onChange={(e) => setEnic(e.target.value)}
                                       name='enic'
                                       placeholder={'Enter N.I.C No'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Address <code>*</code></label>
                            <InputText disabled={true} value={eaddress} onChange={(e) => setEaddress(e.target.value)}
                                       name='eaddress'
                                       placeholder={'Enter Address'} required={true}/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <p><b>Recent Qualification</b></p>
                        <div className="col-md-6">
                            <label>Year <code>*</code></label>
                            <InputText disabled={true} value={year} onChange={(e) => setYear(e.target.value)}
                                       name='year'
                                       placeholder={'Enter Year'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Institute <code>*</code></label>
                            <InputText disabled={true} value={institute} onChange={(e) => setInstitute(e.target.value)}
                                       name='institute'
                                       placeholder={'Enter Institute'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Major Subject <code>*</code></label>
                            <InputText disabled={true} value={subject} onChange={(e) => setSubject(e.target.value)}
                                       name='subject'
                                       placeholder={'Enter Subject'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Marks <code>*</code></label>
                            <InputText disabled={true} value={marks} onChange={(e) => setMarks(e.target.value)}
                                       name='marks'
                                       placeholder={'Enter Marks'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Grade/Division <code>*</code></label>
                            <InputText disabled={true} value={grade} onChange={(e) => setGrade(e.target.value)}
                                       name='grade'
                                       placeholder={'Enter Grade/Division'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Degree/Diploma <code>*</code></label>
                            <InputText disabled={true} value={degree} onChange={(e) => setDegree(e.target.value)}
                                       name='degree'
                                       placeholder={'Enter Degree/Diploma'} required={true}/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <p><b>Office Use</b></p>
                        {
                            function () {
                                if (type == "ielts") {
                                    return (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Batch <code>*</code></label>
                                                <Dropdown disabled={true} name={'batch'} value={batch}
                                                          onChange={(e) => setBatch(e.value)}
                                                          options={[
                                                              {name: "10-12", value: "10-12"},
                                                              {name: "3-5", value: "3-5"},
                                                          ]} optionLabel="name"
                                                          placeholder="Select Batch" className='form-control'/>
                                            </div>
                                        </div>
                                    )
                                } else if (type == "pte") {
                                    return (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Batch <code>*</code></label>
                                                <Dropdown disabled={true} name={'batch'} value={batch}
                                                          onChange={(e) => setBatch(e.value)}
                                                          options={[
                                                              {name: "10-12", value: "10-12"},
                                                              {name: "12-2", value: "12-02"},
                                                              {name: "3-5", value: "3-5"},
                                                              {name: "6-8", value: "6-8"},
                                                          ]} optionLabel="name"
                                                          placeholder="Select Batch" className='form-control'/>
                                            </div>
                                        </div>
                                    )
                                } else if (type == "spoken") {
                                    return (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Batch <code>*</code></label>
                                                <Dropdown disabled={true} name={'batch'} value={batch}
                                                          onChange={(e) => setBatch(e.value)}
                                                          options={[
                                                              {name: "9-1", value: "9-1"},
                                                              {name: "4-8", value: "4-8"},
                                                          ]} optionLabel="name"
                                                          placeholder="Select Batch" className='form-control'/>
                                            </div>
                                        </div>
                                    )
                                } else if (type == "tofel") {
                                    return (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Batch <code>*</code></label>
                                                <Dropdown disabled={true} name={'batch'} value={batch}
                                                          onChange={(e) => setBatch(e.value)}
                                                          options={[
                                                              {name: "10-12", value: "10-12"},
                                                              {name: "3-5", value: "3-5"},
                                                          ]} optionLabel="name"
                                                          placeholder="Select Batch" className='form-control'/>
                                            </div>
                                        </div>
                                    )
                                }
                            }.call(this)
                        }
                        <div className="col-md-6">
                            <label>Instructor <code>*</code></label>
                            <InputText value={teacher} disabled={true} onChange={(e) => setTeacher(e.target.value)}
                                       name='teacher'
                                       placeholder={'Enter Teacher Name'}/>
                        </div>
                        <div className="col-md-6">
                            <label>Fee <code>*</code></label>
                            <InputText disabled={true} value={fee} onChange={(e) => setFee(e.target.value)} name='fee'
                                       placeholder={'Enter Fee'}/>
                        </div>
                        <div className="col-md-6">
                            <label>Remaining <code>*</code></label>
                            <InputText disabled={true} value={remaining}
                            />
                        </div>
                        <div className="row mt-2">
                            <Edit/>&nbsp;&nbsp;&nbsp;
                            <Del/>&nbsp;&nbsp;&nbsp;
                        </div>


                    </div>
                </Dialog>
                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No Student Found !'} paginator
                           onRowClick={async (p) => {
                               let id = customerData[p.index].id;
                               setName(customerData[p.index].Name)
                               setFname(customerData[p.index].FatherName)
                               setGender(customerData[p.index].Gender)
                               setEmail(customerData[p.index].Email)
                               setPhone(customerData[p.index].Phone)
                               setSnic(customerData[p.index].NIC)
                               setDob(customerData[p.index].DOB)
                               setAddress(customerData[p.index].Address)
                               setFinancial(customerData[p.index].Financial)
                               setDate(customerData[p.index].RegistrationDate)
                               setDisability(customerData[p.index].Disability === "true" ? true : false)
                               setRegistered(customerData[p.index].Registered === "true" ? true : false)
                               setEname(customerData[p.index].EName)
                               setRelationship(customerData[p.index].Relationship)
                               setEphone(customerData[p.index].EPhone)
                               setEnic(customerData[p.index].ENIC)
                               setEaddress(customerData[p.index].EAddress)
                               setYear(customerData[p.index].Year)
                               setInstitute(customerData[p.index].Institute)
                               setSubject(customerData[p.index].Subject)
                               setMarks(customerData[p.index].Marks)
                               setGrade(customerData[p.index].Grade)
                               setDegree(customerData[p.index].Degree)
                               setBatch(customerData[p.index].Batch)
                               setTeacher(customerData[p.index].Teacher)
                               setFee(customerData[p.index].Fee)
                               setRemaining(customerData[p.index].Remaining)
                               setUpdateId(id);
                               setVisible(true);
                           }}
                           rows={10}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}>
                    <Column field="Name" header="Name" style={{minWidth: '10rem'}}/>
                    <Column field="FatherName" header="Father Name" style={{minWidth: '10rem'}}/>
                    <Column field="Gender" header="Gender/Sex" style={{minWidth: '10rem'}}/>
                    <Column field="Email" header="Email" style={{minWidth: '10rem'}}/>
                    <Column field="Phone" header="Phone No" style={{minWidth: '10rem'}}/>
                    <Column field="Batch" header="Batch" style={{minWidth: '10rem'}}/>
                    <Column header="Fee Receipt" style={{minWidth: '10rem'}} body={(p) => {
                        if (p.Image) {
                            return (
                                <a href={ImageLink + p.Image} target={'_blank'}>
                                    <img src={ImageLink + p.Image} style={{width: 80, height: 80, borderRadius: 4}}/>
                                </a>
                            )
                        } else {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column field="Result" header="Result" style={{minWidth: '10rem'}}/>
                    <Column header="Document" style={{minWidth: '10rem'}} body={(p) => {
                        if (p.Document) {
                            return (
                                <a href={ImageLink + p.Document} target={'_blank'}>
                                    <img src={'https://cdn-icons-png.flaticon.com/512/6747/6747196.png'}
                                         style={{width: 80, height: 80, borderRadius: 4}}/>
                                </a>
                            )
                        } else {
                            return (
                                <></>
                            )
                        }
                    }}/>
                </DataTable>
            </Card>
        </section>
    );


}
