import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import Header from "../components/Header";
import Menu from "../components/Menu";
import {Dropdown} from "primereact/dropdown";
import {useHistory} from "react-router-dom";

export default function AddFollowUp() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [leadData, setLeadData] = useState([]);
    const [name, setName] = useState('');
    const [leadNo, setLeadNo] = useState('');
    const [leadNoData, setLeadNoData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [parentType, setParentType] = useState('');
    const [callResponse, setCallResponse] = useState("");
    const [priority, setPriority] = useState("");
    const [type, setType] = useState("");
    const [department, setDepartment] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [related, setRelated] = useState("");
    const [assignTo, setAssignTo] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forwardTo, setForwardTo] = useState("");
    const [comment,setComment] = useState("");
    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);
        LoadLeadNo();
        LoadUsers();
        if (res.id) {
            LoadFollowDetail(res.id);
        }

    }, []);

    async function LoadFollowDetail(id) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadFollow");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setLeadData(Data);
                    Data = Data[0];
                    setLeadNo(Data.LeadNo);
                    setParentType(Data.ParentType)
                    setCallResponse(Data.CallResponse)
                    setPriority(Data.Priority)
                    setType(Data.Type)
                    setDepartment(Data.Department)
                    setDueDate(Data.DueDate)
                    setRelated(Data.Related)
                    setAssignTo(Data.AssignTo)
                    setEmail(Data.Email)
                    setPassword(Data.Password)
                    setForwardTo(Data.ForwardTo)
                    setComment(Data.Comment)
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadLeadNo() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadLeadNoData");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    setLeadNoData(result.data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadUsers() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadUsers");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    setUsersData(result.data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("leadno", leadNo);
        formdata.append("CreatedBy", localStorage.getItem("UserId"));
        if (leadData.length > 0) {
            formdata.append("page", "UpdateFollow");
            formdata.append("id", leadData[0].id);
        } else {
            formdata.append("page", "SaveFollow");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    // let Data = result.Data;
                    $('#Form').trigger('reset');
                    setName("");
                    setLeadNo("")
                    setParentType("")
                    setCallResponse("")
                    setPriority("")
                    setType("")
                    setDepartment("")
                    setDueDate("")
                    setRelated("")
                    setAssignTo("")
                    setEmail("")
                    setPassword("")
                    setForwardTo("")
                    setComment("");
                    showSuccess(leadData.length > 0 ? "Record Updated Successfully" : "Record Saved Successfully");
                    LoadLeadNo();
                    LoadUsers();
                    setLeadData([]);
                    setTimeout(()=>{
                        navigate.go(-1)
                    },1000)
                    // setCustomerData(Data);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={()=> navigate.go(-1) } style={{ border : "none",outline : "none",background : "transparent" }}><i className={'fas fa-arrow-left'}></i></button>&nbsp;&nbsp;&nbsp;
                            <span>Add Follow Up</span>
                        </>
                    )
                }}
                className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>File No <code>*</code></label>
                                <Dropdown filter={true} name={'leadNo'} value={leadNo} onChange={(e) => setLeadNo(e.value)}
                                          options={leadNoData} optionLabel="name"
                                          placeholder="Select File No" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Parent Type <code>*</code></label>
                                <Dropdown name={'parentType'} value={parentType}
                                          onChange={(e) => setParentType(e.value)}
                                          options={[
                                              {name: "Lead", value: "Lead"},
                                              {name: "Task", value: "Task"},
                                              {name: "Application", value: "Application"},
                                              {name: "Contact", value: "Contact"},
                                              {name: "Visa", value: "Visa"},
                                          ]} optionLabel="name"
                                          placeholder="Select Parent Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Call Response <code>*</code></label>
                                <Dropdown name={'callResponse'} value={callResponse}
                                          onChange={(e) => setCallResponse(e.value)}
                                          options={[
                                              {name: "Attended", value: "Attended"},
                                              {name: "NCR", value: "NRC"},
                                              {name: "Powered Off", value: "Powered Off"},
                                          ]} optionLabel="name"
                                          placeholder="Select Call Response" className='form-control'/>
                            </div>
                        </div>
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label>Start Date <code>*</code></label>*/}

                        {/*        <Dropdown name={'startDate'} value={startDate} onChange={(e) => setStartDate(e.value)}*/}
                        {/*                  options={[*/}
                        {/*                      {name: "Equal", value: "Equal"},*/}
                        {/*                      {name: "Before", value: "Before"},*/}
                        {/*                      {name: "After", value: "After"},*/}
                        {/*                      {name: "Last 7 days", value: "Last 7 days"},*/}
                        {/*                      {name: "Last Month", value: "Last Month"},*/}
                        {/*                  ]} optionLabel="name"*/}
                        {/*                  placeholder="Select Start Date" className='form-control'/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Priority <code>*</code></label>
                                <Dropdown name={'priority'} value={priority} onChange={(e) => setPriority(e.value)}
                                          options={[
                                              {name: "High", value: "High"},
                                              {name: "Medium", value: "Medium"},
                                              {name: "Low", value: "Low"},
                                          ]} optionLabel="name"
                                          placeholder="Select Priority Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Type <code>*</code></label>
                                <Dropdown name={'type'} value={type} onChange={(e) => setType(e.value)}
                                          options={[
                                              {name: "Pre-mature", value: "Pre-mature"},
                                              {name: "Task", value: "Task"},
                                              {name: "Call", value: "Call"},
                                              {name: "Appointment", value: "Appointment"},
                                          ]} optionLabel="name"
                                          placeholder="Select Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Department Name <code>*</code></label>
                                <InputText id="department" value={department}
                                           onChange={(e) => setDepartment(e.target.value)} name='department'
                                           placeholder={'Enter Department Name'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Due Date <code>*</code></label>
                                <InputText type={'date'} name={'dueDate'} value={dueDate} onChange={(e) => setDueDate(e.target.value)}/>
                                {/*<Dropdown name={'dueDate'} value={dueDate} onChange={(e) => setDueDate(e.value)}*/}
                                {/*          options={[*/}
                                {/*              {name: "Equal", value: "Equal"},*/}
                                {/*              {name: "Before", value: "Before"},*/}
                                {/*              {name: "After", value: "After"},*/}
                                {/*              {name: "Last 7 days", value: "Last 7 days"},*/}
                                {/*              {name: "Last Month", value: "Last Month"},*/}
                                {/*          ]} optionLabel="name"*/}
                                {/*          placeholder="Select Due Date" className='form-control'/>*/}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Related <code>*</code></label>
                                <Dropdown name={'related'} value={related} onChange={(e) => setRelated(e.value)}
                                          options={[
                                              {name: "Equal", value: "Equal"},
                                              {name: "Before", value: "Before"},
                                              {name: "After", value: "After"},
                                              {name: "Last 7 days", value: "Last 7 days"},
                                              {name: "Last Month", value: "Last Month"},
                                          ]} optionLabel="name"
                                          placeholder="Select Start Date" className='form-control'/>
                            </div>
                        </div>
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label>Date Modified <code>*</code></label>*/}
                        {/*        <Dropdown name={'modifiedDate'} value={modifiedDate}*/}
                        {/*                  onChange={(e) => setModifiedDate(e.value)}*/}
                        {/*                  options={[*/}
                        {/*                      {name: "Equal", value: "Equal"},*/}
                        {/*                      {name: "Before", value: "Before"},*/}
                        {/*                      {name: "After", value: "After"},*/}
                        {/*                      {name: "Last 7 days", value: "Last 7 days"},*/}
                        {/*                      {name: "Last Month", value: "Last Month"},*/}
                        {/*                  ]} optionLabel="name"*/}
                        {/*                  placeholder="Select Modified Date" className='form-control'/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label>Date Created <code>*</code></label>*/}
                        {/*        <Dropdown name={'createdDate'} value={createdDate}*/}
                        {/*                  onChange={(e) => setCreatedDate(e.value)}*/}
                        {/*                  options={[*/}
                        {/*                      {name: "Equal", value: "Equal"},*/}
                        {/*                      {name: "Before", value: "Before"},*/}
                        {/*                      {name: "After", value: "After"},*/}
                        {/*                      {name: "Last 7 days", value: "Last 7 days"},*/}
                        {/*                      {name: "Last Month", value: "Last Month"},*/}
                        {/*                  ]} optionLabel="name"*/}
                        {/*                  placeholder="Select Created Date" className='form-control'/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Assign To <code>*</code></label>
                                <Dropdown filter name={'assignTo'} value={assignTo} onChange={(e) => setAssignTo(e.value)}
                                          options={usersData} optionLabel="name"
                                          placeholder="Select Assign To" className='form-control'/>
                            </div>
                        </div>
                        {/*<div className="col-md-6">*/}

                        {/*    <label>File No <code>*</code></label>*/}
                        {/*    <InputText id="fileNo" value={fileNo} onChange={(e) => setFileNo(e.target.value)}*/}
                        {/*               name='fileNo'*/}
                        {/*               placeholder={'Enter File No'} required={true}/>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <label>Registered Date <code>*</code></label>*/}
                        {/*    <InputText id="date" value={date} onChange={(e) => setDate(e.target.value)} name='date'*/}
                        {/*               placeholder={'Enter Registered Date'} required={true}/>*/}
                        {/*</div>*/}
                        <div className="col-md-6 hide">
                            <label>Official Email <code>*</code></label>
                            <InputText id="email" value={email} onChange={(e) => setEmail(e.target.value)} name='email'
                                       placeholder={'Enter Email Address'}/>
                        </div>
                        <div className="col-md-6 hide">
                            <label>Email Password <code>*</code></label>
                            <InputText id="password" value={password} onChange={(e) => setPassword(e.target.value)}
                                       name='password'
                                       placeholder={'Enter Password'}/>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Forward To <code>*</code></label>
                                <Dropdown filter name={'forwardTo'} value={forwardTo} onChange={(e) => setForwardTo(e.value)}
                                          options={usersData} optionLabel="name"
                                          placeholder="Select Forward To" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comment</label>
                                <textarea value={comment} onChange={(e)=> setComment(e.target.value)} name="comment" id="comment" rows="5" className={'form-control'}></textarea>
                            </div>
                        </div>
                        {/*<div className="col-md-6">*/}
                        {/*    <label>Submission / Applied Through <code>*</code></label>*/}
                        {/*    <InputText id="caseline" value={caseline} onChange={(e) => setCaseline(e.target.value)}*/}
                        {/*               name='caseline'*/}
                        {/*               placeholder={'Enter Submission / Applied Through'} required={true}/>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <label>Possible Intake <code>*</code></label>*/}
                        {/*    <InputText id="intake" value={intake} onChange={(e) => setIntake(e.target.value)}*/}
                        {/*               name='intake'*/}
                        {/*               placeholder={'Enter Possible Intake'} required={true}/>*/}
                        {/*</div>*/}
                    </div>
                    <div className="row mt-3">
                        {
                            leadData.length > 0 ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
