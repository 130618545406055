import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import $ from "jquery";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import {File} from "react-feather";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";

export default function Applications() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [visible, setVisible] = useState(false);
    const [updateId, setUpdateId] = useState('');
//    Form Fields
    const [leadNo, setLeadNo] = useState('');
    const [leadNoData, setLeadNoData] = useState([]);
    const [date, setDate] = useState('');
    const [usersData, setUsersData] = useState([]);
    const [assignTo, setAssignTo] = useState("");
    const [country, setCountry] = useState("");
    const [subject, setSubject] = useState("");
    const [intake, setIntake] = useState("");
    const [university, setUniversity] = useState("");
    const [offer, setOffers] = useState("");
    const [rejection, setRejection] = useState("");
    const [finalize, setFinalize] = useState("");
    const [stage, setStage] = useState("");
    const [other, setOther] = useState("");
    const [status, setStatus] = useState("");
    const [comment,setComment] = useState("");
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [branch, setBranch] = useState('');
    const [isDate, setIsDate] = useState(false);
//Expansion
    const [expandedRows, setExpandedRows] = useState(null);
    useEffect(() => {
        LoadData();
    }, [])

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "GetApplications");
        formdata.append("branch", localStorage.getItem("Branch"));
        formdata.append("role", localStorage.getItem("Role"));
        formdata.append("user", localStorage.getItem("UserId"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterData = (e) => {
        let val = e.target.value.toLowerCase();
        if (val) {
            const filter = filterRecord.filter((d) => {
                return d.LeadNo.toLowerCase().indexOf(val) > -1 || d.Country.toLowerCase().indexOf(val) > -1 || d.Subject.toLowerCase().indexOf(val) > -1 || d.Stage.toLowerCase().indexOf(val) > -1
            })
            console.log(filter);
            setCustomerData(filter);
        } else {
            setCustomerData(filterRecord);
        }
    }



    const rowExpansionTemplate = (data) => {
        let arr = [data];
        return (
            <div className="p-3">
                <DataTable value={arr}>
                    <Column field="Rejection" header="Rejection" style={{minWidth: '10rem'}}/>
                    <Column field="Finalize" header="Finalize Option" style={{minWidth: '10rem'}}/>
                    <Column field="Stage" header="Stage" style={{minWidth: '10rem'}}/>
                    <Column field="Other" header="Other Stage" style={{minWidth: '10rem'}}/>
                    <Column header="Document 1" style={{minWidth: '6rem'}} body={(p) => {
                        return (
                            <a href={ImageLink + p.Doc1} target={'_blank'}>
                                <span>{p.Doc1}</span>&nbsp;&nbsp;<File/>
                            </a>
                        )
                    }}/>
                    <Column header="Document 2" style={{minWidth: '6rem'}} body={(p) => {
                        return (
                            <a href={ImageLink + p.Doc2} target={'_blank'}>
                                <span>{p.Doc2}</span>&nbsp;&nbsp;<File/>
                            </a>
                        )
                    }}/>
                    <Column header="Document 3" style={{minWidth: '6rem'}} body={(p) => {
                        return (
                            <a href={ImageLink + p.Doc3} target={'_blank'}>
                                <span>{p.Doc3}</span>&nbsp;&nbsp;<File/>
                            </a>
                        )
                    }}/>
                    <Column header="Document 4" style={{minWidth: '6rem'}} body={(p) => {
                        return (
                            <a href={ImageLink + p.Doc4} target={'_blank'}>
                                <span>{p.Doc4}</span>&nbsp;&nbsp;<File/>
                            </a>
                        )
                    }}/>
                    <Column header="Document 5" style={{minWidth: '6rem'}} body={(p) => {
                        return (
                            <a href={ImageLink + p.Doc5} target={'_blank'}>
                                <span>{p.Doc5}</span>&nbsp;&nbsp;<File/>
                            </a>
                        )
                    }}/>

                    <Column header="Status" style={{minWidth: '8rem'}} body={(p) => {
                        if (p.Status !== "") {
                            return (
                                <div style={{
                                    backgroundColor: function () {
                                        if (p.Status == "Pending")
                                            return "#000"
                                        else if (p.Status == "Rejected")
                                            return "#ED9393"
                                        else if (p.Status == "Accepted")
                                            return "#82CCB0"
                                    }.call(this),
                                    padding: "4px 10px",
                                    borderRadius: 5,
                                    textAlign: "center",
                                    color: "#fff"
                                }}>
                                    <span>{p.Status}</span>
                                </div>
                            )
                        } else {
                            return (
                                <></>
                            )
                        }
                    }}/>
                    <Column field="CreatedBy" header="Created By" style={{minWidth: '8rem'}}/>
                    {/*<Column header="Edit" style={{minWidth: '5rem'}} body={Edit}/>*/}
                    {/*<Column header="Delete" style={{minWidth: '5rem'}} body={Del}/>*/}
                </DataTable>
            </div>
        );
    };
    const Del=()=>{
        const acceptDelete = async () => {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
            var formdata = new FormData();
            formdata.append("page", "DeleteApplication");
            formdata.append("id", updateId);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${AppLink}Leads.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    setLoading(false);
                    if (result.status == "1") {
                        // let Data = result.Data;
                        LoadData();
                        showSuccess("Record Deleted Successfully");
                    } else {
                        showError();
                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                    }
                })
                .catch(error => console.log('error', error));
        }
        if (localStorage.getItem("Role") !== "Operator" && localStorage.getItem("Role") !== "Counselor") {
            return (
                <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                    confirmPopup({
                        target: event.currentTarget,
                        message: 'Do you want to delete this record?',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept() {
                            acceptDelete()
                        },
                        reject() {
                            console.log("Reject");
                        }
                    });


                }}/>
            )
        }
        else
        {
            <></>
        }

    }
    const Edit=()=>{
        return (
            <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                history.push("/add-application?id=" + updateId);
            }}/>
        )
    }


    function filterDateRecord() {
        if (startDate && endDate) {
            const filter = filterRecord.filter((d) => {
                return d.Date >= startDate && d.Date <= endDate
            })
            console.log(filter);
            setCustomerData(filter);
            setIsDate(true);
        } else {
            toast.current.show({
                severity: 'warn',
                summary: 'Date Empty !',
                detail: 'Please select From and To Date for filter record',
                life: 3000
            });
            setCustomerData(filterRecord);
            setIsDate(false);
        }
    }

    function filterRecordBranch(b) {
        if (b) {
            if (b !== "All") {
                if (isDate) {
                    const filter = customerData.filter((d) => {
                        return d.Branch.toLowerCase() === b.toString().toLowerCase()
                    })
                    console.log(filter);
                    setCustomerData(filter);
                }
                else
                {
                    const filter = filterRecord.filter((d) => {
                        return d.Branch.toLowerCase() === b.toString().toLowerCase()
                    })
                    console.log(filter);
                    setCustomerData(filter);
                }
            }
            else
            {
                setEndDate("")
                setStartDate("")
                setIsDate(false);
                setCustomerData(filterRecord);
            }
        }
        else if (isDate)
        {
            const filter = filterRecord.filter((d) => {
                return d.Date >= startDate && d.Date <= endDate
            })
            console.log(filter);
            setCustomerData(filter);
        }
        else
        {
            setCustomerData(filterRecord);
        }
    }

    return (
        <section className="content">
            <Card header={() => {
                return (
                    <div className="row justify-content-end gap-4 mt-4">
                        <Button onClick={() => history.push('/add-application')} label="Add Application"
                                icon="pi pi-plus"
                                style={{width: "auto"}}/>
                    </div>
                )
            }
            }
                  title={() => {
                      return (
                          <>
                              <button onClick={() => history.go(-1)}
                                      style={{border: "none", outline: "none", background: "transparent"}}><i
                                  className={'fas fa-arrow-left'}></i></button>
                              &nbsp;&nbsp;&nbsp;
                              <span>Applications</span>
                              <div className="row justify-content-between">
                                  <div className="col-md-3 mt-1">
                                      <div className="form-group">
                                          <InputText type={'search'} onChange={(e) => filterData(e)}
                                                     placeholder={'Keyword Search...'}/>
                                      </div>
                                  </div>
                                  <div className="col-md-9 mt-1">
                                      <div className="row">
                                          <div className="col-md-3 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'} value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-3 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'} value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-2 mt-1">
                                              <div className="form-group">
                                                  <button onClick={filterDateRecord} style={{marginTop: 10}}
                                                          className={'btn btn-primary form-control'}>Search
                                                  </button>
                                              </div>
                                          </div>
                                          {
                                              localStorage.getItem("Role") == "Admin" ?
                                                  <div className="col-md-4 mt-1">
                                                      <div className="form-group">
                                                          <Dropdown name={'branch'} value={branch} onChange={(e) => {
                                                              setBranch(e.value);
                                                              filterRecordBranch(e.value);
                                                          }} options={[
                                                              {
                                                                  name: "--Select Branch--",
                                                                  value: ""
                                                              },{
                                                                  name: "Multan",
                                                                  value: "Multan"
                                                              },
                                                              {
                                                                  name: "Gujranwala",
                                                                  value: "Gujranwala"
                                                              },
                                                              {
                                                                  name: "Sahiwal",
                                                                  value: "Sahiwal"
                                                              },
                                                              {
                                                                  name: "Bahawalpur",
                                                                  value: "Bahawalpur"
                                                              },
                                                              {
                                                                  name: "All Branches",
                                                                  value: "All"
                                                              }
                                                          ]} optionLabel="name"
                                                                    placeholder="Select a Branch" className='form-control'/>
                                                      </div>
                                                  </div> : null
                                          }

                                      </div>
                                  </div>
                              </div>
                          </>
                      )
                  }}
                  className="card">
                <Toast ref={toast}/>
                <Dialog header="Application Detail" visible={visible} style={{width: '50vw'}}
                        onHide={() => setVisible(false)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>File No <code>*</code></label>
                                <InputText value={leadNo} disabled={true} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Registration Date <code>*</code></label>
                                <InputText id="date" value={date}
                                           onChange={(e) => setDate(e.target.value)} name='date' disabled={true}
                                           type={'date'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Assign To <code>*</code></label>
                                <InputText value={assignTo} disabled={true} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Country <code>*</code></label>
                                <InputText id="country" value={country}
                                           onChange={(e) => setCountry(e.target.value)} name='country' disabled={true}
                                           placeholder={'Enter Country Name'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Subject / Course <code>*</code></label>
                                <InputText id="course" value={subject} disabled={true}
                                           onChange={(e) => setSubject(e.target.value)} name='course'
                                           placeholder={'Enter Subject/Course Detail'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Intake <code>*</code></label>
                                <InputText id="intake" value={intake} disabled={true}
                                           onChange={(e) => setIntake(e.target.value)} name='intake'
                                           placeholder={'Enter Intake Value'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Universities Applied <code>*</code></label>
                                <textarea placeholder={'Enter University Names'} id={'university'} name={'university'} disabled={true}
                                          className={'form-control'} value={university}
                                          onChange={(e) => setUniversity(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Offer Received <code>*</code></label>
                                <textarea placeholder={'Enter Offer Received Detail'} id={'offers'} name={'offers'} disabled={true}
                                          className={'form-control'} value={offer}
                                          onChange={(e) => setOffers(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Rejection <code>*</code></label>
                                <InputText id="rejection" value={rejection}
                                           onChange={(e) => setRejection(e.target.value)} name='rejection' disabled={true}
                                           placeholder={'Enter Rejection Detail'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Finalized Option <code>*</code></label>
                                <InputText id="final" value={finalize}
                                           onChange={(e) => setFinalize(e.target.value)} name='final' disabled={true}
                                           placeholder={'Enter Finalized Option'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Email <code>*</code></label>
                                <InputText value={email} disabled={true} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Password <code>*</code></label>
                                <InputText value={password} disabled={true} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Stage <code>*</code></label>
                                <Dropdown name={'stage'} value={stage}
                                          onChange={(e) => setStage(e.value)} disabled={true}
                                          options={[
                                              {name: "Application", value: "Application"},
                                              {name: "Proceeding", value: "Proceeding"},
                                              {name: "GTE/Interviews", value: "GTE/Interviews"},
                                              {name: "CAS/COE", value: "CAS/COE"},
                                              {name: "Other", value: "Other"},
                                          ]} optionLabel="name"
                                          placeholder="Select Stage" className='form-control'/>
                            </div>
                        </div>
                        {
                            stage == "Other" ?
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Other Stage <code>*</code></label>
                                        <InputText id="other" value={other} disabled={true}
                                                   onChange={(e) => setOther(e.target.value)} name='other'
                                                   placeholder={'Enter Other Stage'}/>
                                    </div>
                                </div> : null
                        }
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status <code>*</code></label>
                                <Dropdown name={'Status'} value={status} disabled={true}
                                          onChange={(e) => setStatus(e.value)}
                                          options={[
                                              {name: "Pending", value: "Pending"},
                                              {name: "Rejected", value: "Rejected"},
                                              {name: "Accepted", value: "Accepted"},
                                          ]} optionLabel="name"
                                          placeholder="Select Status" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comment</label>
                                <textarea value={comment} onChange={(e)=> setComment(e.target.value)} name="comment" id="comment" rows="5" className={'form-control'}></textarea>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-3">
                        <Edit/>&nbsp;&nbsp;&nbsp;
                        <Del/>&nbsp;&nbsp;&nbsp;
                    </div>
                </Dialog>
                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No Applications Found !'} paginator
                           rows={10}
                           onRowClick={async (p) => {
                               let id = customerData[p.index].id;
                               setLeadNo(customerData[p.index].LeadNo);
                               setDate(customerData[p.index].Date);
                               setAssignTo(customerData[p.index].AssignTo);
                               setCountry(customerData[p.index].Country);
                               setSubject(customerData[p.index].Subject);
                               setIntake(customerData[p.index].Intake);
                               setUniversity(customerData[p.index].University);
                               setOffers(customerData[p.index].Offer);
                               setRejection(customerData[p.index].Rejection);
                               setFinalize(customerData[p.index].Finalize);
                               setStage(customerData[p.index].Stage);
                               setStatus(customerData[p.index].Status);
                               setEmail(customerData[p.index].Email);
                               setPassword(customerData[p.index].Password);
                               setComment(customerData[p.index].Comment);
                               setUpdateId(id);
                               setVisible(true);
                           }}
                           rowClassName={'cursor-pointer'}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}
                           expandedRows={expandedRows} onRowToggle={(e) => {
                    setExpandedRows(e.data);
                    console.log(e.data);
                }}
                           rowExpansionTemplate={rowExpansionTemplate}
                >
                    <Column expander={true} style={{width: '5rem'}}/>
                    <Column field="LeadNo" header="File No" style={{minWidth: '10rem'}}/>
                    <Column field="Date" header="Registration Date" style={{minWidth: '10rem'}}/>
                    <Column field="AssignTo" header="Assign To" style={{minWidth: '10rem'}}/>
                    <Column field="Country" header="Country" style={{minWidth: '10rem'}}/>
                    <Column field="Subject" header="Subject/Course" style={{minWidth: '10rem'}}/>
                    <Column field="Intake" header="Intake" style={{minWidth: '10rem'}}/>
                    <Column field="University" header="University" style={{minWidth: '10rem'}}/>
                    <Column field="Offer" header="Offers Received" style={{minWidth: '10rem'}}/>

                </DataTable>
            </Card>
        </section>
    );
}
