import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import {Dropdown} from "primereact/dropdown";
import {useHistory} from "react-router-dom";

export default function AddApplication() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [leadNo, setLeadNo] = useState('');
    const [leadNoData, setLeadNoData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updateId, setUpdateId] = useState('');
    const [date, setDate] = useState('');
    const [usersData, setUsersData] = useState([]);
    const [assignTo, setAssignTo] = useState("");
    const [country, setCountry] = useState("");
    const [subject, setSubject] = useState("");
    const [intake, setIntake] = useState("");
    const [university, setUniversity] = useState("");
    const [offer, setOffers] = useState("");
    const [rejection, setRejection] = useState("");
    const [finalize, setFinalize] = useState("");
    const [stage, setStage] = useState("");
    const [other, setOther] = useState("");
    const [status, setStatus] = useState("");
    const [comment,setComment] = useState("");
    const [submitted,setSubmitted] = useState("");
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);
        LoadUsers();
        LoadLeadNo();
        if (res.id) {
            setUpdateId(res.id);
            LoadFollowDetail(res.id);
        }

    }, []);

    async function LoadFollowDetail(id) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadApplication");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    Data = Data[0];
                    setDate(Data.Date);
                    setCountry(Data.Country);
                    setSubject(Data.Subject);
                    setIntake(Data.Intake);
                    setUniversity(Data.University);
                    setOffers(Data.Offer);
                    setRejection(Data.Rejection);
                    setFinalize(Data.Finalize);
                    setStage(Data.Stage);
                    setAssignTo(Data.AssignTo)
                    setOther(Data.Other)
                    setStatus(Data.Status)
                    setLeadNo(Data.LeadNo)
                    setComment(Data.Comment)
                    setSubmitted(Data.Submitted)
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadUsers() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadUsers");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    setUsersData(result.data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("CreatedBy", localStorage.getItem("UserId"));
        formdata.append("other", other);
        formdata.append("LeadNo", leadNo);
        if (updateId) {
            formdata.append("page", "UpdateApplication");
            formdata.append("id", updateId);
        } else {
            formdata.append("page", "SaveApplication");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    // let Data = result.Data;
                    $('#Form').trigger('reset');
                    setDate("");
                    setCountry("");
                    setSubject("");
                    setIntake("");
                    setUniversity("");
                    setOffers("");
                    setRejection("");
                    setFinalize("");
                    setStage("");
                    setAssignTo("");
                    setStatus("");
                    setLeadNo("");
                    setComment("");
                    setSubmitted("")
                    showSuccess(updateId ? "Record Updated Successfully" : "Record Saved Successfully");
                    setUpdateId("");
                    LoadUsers();
                    LoadLeadNo();
                    setTimeout(()=>{
                        navigate.go(-1)
                    },1000)
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadLeadNo() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadLeadNoData");
        formdata.append("role", localStorage.getItem("Role"));
        formdata.append("user", localStorage.getItem("UserId"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    setLeadNoData(result.data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Add Application</span>
                        </>
                    )
                }}
                className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>File No </label>
                                <Dropdown filter={true} name={'leadNo'} value={leadNo} onChange={(e) => setLeadNo(e.value)}
                                          options={leadNoData} optionLabel="name"
                                          placeholder="Select File No" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Registration Date </label>
                                <InputText id="date" value={date}
                                           onChange={(e) => setDate(e.target.value)} name='date'
                                           type={'date'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Assign To </label>
                                <Dropdown filter={true} name={'assignTo'} value={assignTo} onChange={(e) => setAssignTo(e.value)}
                                          options={usersData} optionLabel="name"
                                          placeholder="Select Assign To" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Country </label>
                                <InputText id="country" value={country}
                                           onChange={(e) => setCountry(e.target.value)} name='country'
                                           placeholder={'Enter Country Name'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Subject / Course </label>
                                <InputText id="course" value={subject}
                                           onChange={(e) => setSubject(e.target.value)} name='course'
                                           placeholder={'Enter Subject/Course Detail'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Intake </label>
                                <InputText id="intake" value={intake}
                                           onChange={(e) => setIntake(e.target.value)} name='intake'
                                           placeholder={'Enter Intake Value'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Universities Applied </label>
                                <textarea placeholder={'Enter University Names'} id={'university'} name={'university'}
                                          className={'form-control'} value={university}
                                          onChange={(e) => setUniversity(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Offer Received </label>
                                <textarea placeholder={'Enter Offer Received Detail'} id={'offers'} name={'offers'}
                                          className={'form-control'} value={offer}
                                          onChange={(e) => setOffers(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Rejection </label>
                                <InputText id="rejection" value={rejection}
                                           onChange={(e) => setRejection(e.target.value)} name='rejection'
                                           placeholder={'Enter Rejection Detail'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Finalized Option </label>
                                <InputText id="final" value={finalize}
                                           onChange={(e) => setFinalize(e.target.value)} name='final'
                                           placeholder={'Enter Finalized Option'}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Stage </label>
                                <Dropdown name={'stage'} value={stage}
                                          onChange={(e) => setStage(e.value)}
                                          options={[
                                              {name: "Application", value: "Application"},
                                              {name: "Proceeding", value: "Proceeding"},
                                              {name: "GTE/Interviews", value: "GTE/Interviews"},
                                              {name: "CAS/COE", value: "CAS/COE"},
                                              {name: "Other", value: "Other"},
                                          ]} optionLabel="name"
                                          placeholder="Select Stage" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Application Submitted <code>*</code></label>
                            <InputText id="submitted" value={submitted} onChange={(e) => setSubmitted(e.target.value)} name='submitted'
                                       placeholder={'....'}/>
                        </div>
                        <div className="col-md-6">
                            <label>Official Email <code>*</code></label>
                            <InputText id="email" value={email} onChange={(e) => setEmail(e.target.value)} name='email'
                                       placeholder={'Enter Email Address'}/>
                        </div>
                        <div className="col-md-6">
                            <label>Email Password <code>*</code></label>
                            <InputText id="password" value={password} onChange={(e) => setPassword(e.target.value)}
                                       name='password'
                                       placeholder={'Enter Password'}/>
                        </div>
                        {
                            stage == "Other" ?
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Other Stage </label>
                                        <InputText id="other" value={other}
                                                   onChange={(e) => setOther(e.target.value)} name='other'
                                                   placeholder={'Enter Other Stage'}/>
                                    </div>
                                </div> : null
                        }

                                <div className={'row'}>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document 1 </label>
                                            <InputText name='doc1'
                                                       placeholder={'Enter Enter Document Title'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document </label>
                                            <InputText name='upload1' type={'file'} className={'form-control'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document 2 </label>
                                            <InputText name='doc2'
                                                       placeholder={'Enter Enter Document Title'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document </label>
                                            <InputText name='upload2' type={'file'} className={'form-control'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document 3 </label>
                                            <InputText name='doc3'
                                                       placeholder={'Enter Enter Document Title'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document </label>
                                            <InputText name='upload3' type={'file'} className={'form-control'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document 4 </label>
                                            <InputText name='doc4'
                                                       placeholder={'Enter Enter Document Title'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document </label>
                                            <InputText name='upload4' type={'file'} className={'form-control'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document 5 </label>
                                            <InputText name='doc5'
                                                       placeholder={'Enter Enter Document Title'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document </label>
                                            <InputText name='upload5' type={'file'} className={'form-control'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document 6 </label>
                                            <InputText name='doc6'
                                                       placeholder={'Enter Enter Document Title'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document </label>
                                            <InputText name='upload6' type={'file'} className={'form-control'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document 7 </label>
                                            <InputText name='doc7'
                                                       placeholder={'Enter Enter Document Title'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document </label>
                                            <InputText name='upload7' type={'file'} className={'form-control'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document 8 </label>
                                            <InputText name='doc8'
                                                       placeholder={'Enter Enter Document Title'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document </label>
                                            <InputText name='upload8' type={'file'} className={'form-control'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document 9 </label>
                                            <InputText name='doc9'
                                                       placeholder={'Enter Enter Document Title'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document </label>
                                            <InputText name='upload9' type={'file'} className={'form-control'} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document 10 </label>
                                            <InputText name='doc10'
                                                       placeholder={'Enter Enter Document Title'}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Document </label>
                                            <InputText name='upload10' type={'file'} className={'form-control'} />
                                        </div>
                                    </div>
                                </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Status </label>
                                <Dropdown name={'Status'} value={status}
                                          onChange={(e) => setStatus(e.value)}
                                          options={[
                                              {name: "Pending", value: "Pending"},
                                              {name: "Rejected", value: "Rejected"},
                                              {name: "Accepted", value: "Accepted"},
                                          ]} optionLabel="name"
                                          placeholder="Select Status" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comment</label>
                                <textarea value={comment} onChange={(e)=> setComment(e.target.value)} name="comment" id="comment" rows="5" className={'form-control'}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {
                            updateId ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
