import React from "react";
import {NavLink, useHistory} from 'react-router-dom';

const Menu = () => {
    const history = useHistory();
    return (
        <>
            <div>
                <aside id="leftsidebar" className="sidebar">
                    <div className="menu">
                        <ul className="list">
                            <li className="sidebar-user-panel active">
                                <div className="user-panel">
                                    <div className=" image">
                                        <img src="assets/images/user_120.png" className="user-img-style"
                                             alt="User Image"/>
                                    </div>
                                </div>
                                <div className="profile-usertitle">
                                    <div className="sidebar-userpic-name"> {localStorage.getItem("UserName")}</div>
                                    <div className="profile-usertitle-job ">{localStorage.getItem("Role")}</div>
                                </div>
                            </li>
                            <li className="header">-- Main</li>
                            <li>
                                <NavLink to={'/'} exact={true}>
                                    <i data-feather="monitor"></i>
                                    <span>Home</span>
                                </NavLink>
                            </li>

                            <li className={localStorage.getItem("Role") === "Operator" || localStorage.getItem("Role") === "Counselor"  ? "hide" : ""}>
                                <NavLink to={'/users'} exact={true}>
                                    <i data-feather="users"></i>
                                    <span>Users Config.</span>
                                </NavLink>
                            </li>
                            <li>
                                <a href="#" class="menu-toggle">
                                    <i data-feather="users"></i>
                                    <span>Consultancy</span>
                                </a>
                                <ul class="ml-menu">
                                    <li className={localStorage.getItem("Role") === "Counselor"  ? "hide" : ""}>
                                        <NavLink to={'/leads-list'} exact={true}>
                                            <i data-feather="settings"></i>
                                            <span>Leads</span>
                                        </NavLink>
                                    </li>
                                    <li className={localStorage.getItem("Role") === "Counselor"  ? "hide" : ""}>
                                        <NavLink to={'/follow-up'}>
                                            <i data-feather="book"></i>
                                            <span>Follow Up</span>
                                        </NavLink>
                                    </li>
                                    <li className={localStorage.getItem("Role") === "Operator" ? "hide" : ""}>
                                        <NavLink to={'/applications'}>
                                            <i data-feather="award"></i>
                                            <span>Applications</span>
                                        </NavLink>
                                    </li>
                                    <li className={localStorage.getItem("Role") === "Operator" ? "hide" : ""}>
                                        <NavLink to={'/visa'}>
                                            <i data-feather="tv"></i>
                                            <span>Visa</span>
                                        </NavLink>
                                    </li>
                                    <li className={localStorage.getItem("Role") === "Operator" ? "hide" : ""}>
                                        <NavLink to={'/gte'}>
                                            <i data-feather="file"></i>
                                            <span>GTE</span>
                                        </NavLink>
                                    </li>
                                    <li className={localStorage.getItem("Role") === "Operator" || localStorage.getItem("Role") === "Counselor" ? "hide" : ""}>
                                        <NavLink to={'/status'}>
                                            <i data-feather="user"></i>
                                            <span>Status</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className={localStorage.getItem("Role") === "Counselor"  ? "hide" : ""}>
                                <a href="#" className="menu-toggle">
                                    <i data-feather="globe"></i>
                                    <span>Language</span>
                                </a>
                                <ul className="ml-menu">
                                    <li>
                                        <NavLink to={'/ielts'} exact={true}>
                                            <span>IELTS</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/pte'} exact={true}>
                                            <span>PTE</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/spoken'} exact={true}>
                                            <span>Spoken</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/crash'} exact={true}>
                                            <span>Crash Course</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/tofel'} exact={true}>
                                            <span>Other Tests</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>


                        </ul>
                    </div>
                </aside>
            </div>
        </>
    )
}
export default Menu;
