import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {useHistory} from "react-router-dom";
import AppLink, {ImageLink} from "../components/Link";
import {Toast} from "primereact/toast";
import $ from "jquery";
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup';
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";

export default function FollowUp() {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [customerData, setCustomerData] = useState([]);
    const [filterRecord, setFilterRecord] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [visible, setVisible] = useState(false);
    const [updateId, setUpdateId] = useState('');
//    Form Fields
    const [leadData, setLeadData] = useState([]);
    const [name, setName] = useState('');
    const [leadNo, setLeadNo] = useState('');
    const [leadNoData, setLeadNoData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [parentType, setParentType] = useState('');
    const [callResponse, setCallResponse] = useState("");
    const [priority, setPriority] = useState("");
    const [type, setType] = useState("");
    const [department, setDepartment] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [related, setRelated] = useState("");
    const [assignTo, setAssignTo] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forwardTo, setForwardTo] = useState("");
    const [comment,setComment] = useState('');
    const [branch, setBranch] = useState('');
    const [isDate, setIsDate] = useState(false);
//Expansion
    const [expandedRows, setExpandedRows] = useState(null);
    useEffect(() => {
        LoadData();
    }, [])


    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const LoadData = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData(this);
        formdata.append("page", "GetFollows");
        formdata.append("branch", localStorage.getItem("Branch"));
        formdata.append("role", localStorage.getItem("Role"));
        formdata.append("user", localStorage.getItem("UserId"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setCustomerData(Data);
                    setFilterRecord(Data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const filterData = (e) => {
        let val = e.target.value.toLowerCase();
        if (val) {
            const filter = filterRecord.filter((d) => {
                return d.LeadNo.toLowerCase().indexOf(val) > -1 || d.Name.toLowerCase().indexOf(val) > -1
            })
            console.log(filter);
            setCustomerData(filter);
        } else {
            setCustomerData(filterRecord);
        }
    }



    const rowExpansionTemplate = (data) => {
        let arr = [data];
        return (
            <div className="p-3">
                <DataTable value={arr}>

                    <Column field="ParentType" header="Parent Type" style={{minWidth: '10rem'}}/>
                    <Column field="CallResponse" header="Call Response" style={{minWidth: '10rem'}}/>
                    <Column field="Priority" header="Priority" style={{minWidth: '10rem'}}/>
                    <Column field="Type" header="Type" style={{minWidth: '10rem'}}/>
                    <Column field="Department" header="Department" style={{minWidth: '10rem'}}/>
                    <Column field="DueDate" header="Due Date" style={{minWidth: '10rem'}}/>
                    <Column field="Related" header="Related" style={{minWidth: '10rem'}}/>

                    <Column field="CreatedBy" header="Created By" style={{minWidth: '8rem'}}/>
                    {/*<Column field="Branch" header="Branch" style={{minWidth: '10rem'}}/>*/}
                    {/*<Column header="Edit" style={{minWidth: '5rem'}} body={Edit}/>*/}
                    {/*<Column header="Delete" style={{minWidth: '5rem'}} body={Del}/>*/}
                </DataTable>
            </div>
        );
    };

    const Del=()=>{
        const acceptDelete = async () => {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
            var formdata = new FormData();
            formdata.append("page", "DeleteFollow");
            formdata.append("id", updateId);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${AppLink}Leads.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    setLoading(false);
                    if (result.status == "1") {
                        // let Data = result.Data;
                        LoadData();
                        showSuccess("Record Deleted Successfully");
                    } else {
                        showError();
                        console.log("Server Error. Please Try Again Later.(Code : 403)");
                    }
                })
                .catch(error => console.log('error', error));
        }
        if (localStorage.getItem("Role") !== "Operator" && localStorage.getItem("Role") !== "Counselor") {
            return (
                <Button icon={'pi pi-trash'} severity={'danger'} onClick={async (event) => {
                    confirmPopup({
                        target: event.currentTarget,
                        message: 'Do you want to delete this record?',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept() {
                            acceptDelete()
                        },
                        reject() {
                            console.log("Reject");
                        }
                    });


                }}/>
            )
        }
        else
        {
            return (
                <></>
            )
        }
    }

    const Edit=()=>{
        return (
            <Button icon={'pi pi-pencil'} severity={'info'} onClick={() => {
                history.push("/add-follow?id=" + updateId);
            }}/>
        )
    }

    function filterDateRecord() {
        if (startDate && endDate) {
            const filter = filterRecord.filter((d) => {
                return d.Date >= startDate && d.Date <= endDate
            })
            console.log(filter);
            setCustomerData(filter);
            setIsDate(true);
        } else {
            toast.current.show({
                severity: 'warn',
                summary: 'Date Empty !',
                detail: 'Please select From and To Date for filter record',
                life: 3000
            });
            setCustomerData(filterRecord);
            setIsDate(false);
        }
    }

    function filterRecordBranch(b) {
        if (b) {
            if (b !== "All") {
                if (isDate) {
                    const filter = customerData.filter((d) => {
                        return d.Branch.toLowerCase() === b.toString().toLowerCase()
                    })
                    console.log(filter);
                    setCustomerData(filter);
                }
                else
                {
                    const filter = filterRecord.filter((d) => {
                        return d.Branch.toLowerCase() === b.toString().toLowerCase()
                    })
                    console.log(filter);
                    setCustomerData(filter);
                }
            }
            else
            {
                setEndDate("")
                setStartDate("")
                setIsDate(false);
                setCustomerData(filterRecord);
            }
        }
        else if (isDate)
        {
            const filter = filterRecord.filter((d) => {
                return d.Date >= startDate && d.Date <= endDate
            })
            console.log(filter);
            setCustomerData(filter);
        }
        else
        {
            setCustomerData(filterRecord);
        }
    }


    return (
        <section className="content">
            <Card header={() => {
                return (
                    <div className="row justify-content-end gap-4 mt-4">
                        <Button onClick={() => history.push('/add-follow')} label="Add Follow Up"
                                icon="pi pi-plus"
                                style={{width: "auto"}}/>
                    </div>
                )
            }
            }
                  title={() => {
                      return (
                          <>
                              <button onClick={() => history.go(-1)}
                                      style={{border: "none", outline: "none", background: "transparent"}}><i
                                  className={'fas fa-arrow-left'}></i></button>
                              &nbsp;&nbsp;&nbsp;
                              <span>Follow Up</span>
                              <div className="row justify-content-between">
                                  <div className="col-md-3 mt-1">
                                      <div className="form-group">
                                          <InputText type={'search'} onChange={(e) => filterData(e)}
                                                     placeholder={'Keyword Search...'}/>
                                      </div>
                                  </div>
                                  <div className="col-md-9 mt-1">
                                      <div className="row">
                                          <div className="col-md-3 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'} value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-3 mt-1">
                                              <div className="form-group">
                                                  <InputText type={'date'} value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                              </div>
                                          </div>
                                          <div className="col-md-2 mt-1">
                                              <div className="form-group">
                                                  <button onClick={filterDateRecord} style={{marginTop: 10}}
                                                          className={'btn btn-primary form-control'}>Search
                                                  </button>
                                              </div>
                                          </div>
                                          {
                                              localStorage.getItem("Role") == "Admin" ?
                                                  <div className="col-md-4 mt-1">
                                                      <div className="form-group">
                                                          <Dropdown name={'branch'} value={branch} onChange={(e) => {
                                                              setBranch(e.value);
                                                              filterRecordBranch(e.value);
                                                          }} options={[
                                                              {
                                                                  name: "--Select Branch--",
                                                                  value: ""
                                                              },{
                                                                  name: "Multan",
                                                                  value: "Multan"
                                                              },
                                                              {
                                                                  name: "Gujranwala",
                                                                  value: "Gujranwala"
                                                              },
                                                              {
                                                                  name: "Sahiwal",
                                                                  value: "Sahiwal"
                                                              },
                                                              {
                                                                  name: "Bahawalpur",
                                                                  value: "Bahawalpur"
                                                              },
                                                              {
                                                                  name: "All Branches",
                                                                  value: "All"
                                                              }
                                                          ]} optionLabel="name"
                                                                    placeholder="Select a Branch" className='form-control'/>
                                                      </div>
                                                  </div> : null
                                          }

                                      </div>
                                  </div>
                              </div>
                          </>
                      )
                  }}
                  className="card">
                <Toast ref={toast}/>
                <Dialog header="FollowUp Detail" visible={visible} style={{width: '50vw'}}
                        onHide={() => setVisible(false)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>File No <code>*</code></label>
                                <InputText value={leadNo} disabled={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Parent Type <code>*</code></label>
                                <Dropdown name={'parentType'} value={parentType}
                                          onChange={(e) => setParentType(e.value)} disabled={true}
                                          options={[
                                              {name: "Lead", value: "Lead"},
                                              {name: "Task", value: "Task"},
                                              {name: "Application", value: "Application"},
                                              {name: "Contact", value: "Contact"},
                                              {name: "Visa", value: "Visa"},
                                          ]} optionLabel="name"
                                          placeholder="Select Parent Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Call Response <code>*</code></label>
                                <Dropdown name={'callResponse'} value={callResponse}
                                          onChange={(e) => setCallResponse(e.value)} disabled={true}
                                          options={[
                                              {name: "Attended", value: "Attended"},
                                              {name: "NCR", value: "NRC"},
                                              {name: "Powered Off", value: "Powered Off"},
                                          ]} optionLabel="name"
                                          placeholder="Select Call Response" className='form-control'/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Priority <code>*</code></label>
                                <Dropdown name={'priority'} value={priority} onChange={(e) => setPriority(e.value)}
                                          disabled={true}
                                          options={[
                                              {name: "High", value: "High"},
                                              {name: "Medium", value: "Medium"},
                                              {name: "Low", value: "Low"},
                                          ]} optionLabel="name"
                                          placeholder="Select Priority Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Type <code>*</code></label>
                                <Dropdown name={'type'} value={type} onChange={(e) => setType(e.value)} disabled={true}
                                          options={[
                                              {name: "Pre-mature", value: "Pre-mature"},
                                              {name: "Task", value: "Task"},
                                              {name: "Call", value: "Call"},
                                              {name: "Appointment", value: "Appointment"},
                                          ]} optionLabel="name"
                                          placeholder="Select Type" className='form-control'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Department Name <code>*</code></label>
                                <InputText id="department" value={department}
                                           onChange={(e) => setDepartment(e.target.value)} disabled={true}
                                           name='department'
                                           placeholder={'Enter Department Name'} required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Due Date <code>*</code></label>
                                <InputText type={'date'} name={'dueDate'} value={dueDate} disabled={true}
                                           onChange={(e) => setDueDate(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Related <code>*</code></label>
                                <Dropdown name={'related'} value={related} disabled={true}
                                          onChange={(e) => setRelated(e.value)}
                                          options={[
                                              {name: "Equal", value: "Equal"},
                                              {name: "Before", value: "Before"},
                                              {name: "After", value: "After"},
                                              {name: "Last 7 days", value: "Last 7 days"},
                                              {name: "Last Month", value: "Last Month"},
                                          ]} optionLabel="name"
                                          placeholder="Select Start Date" className='form-control'/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Assign To <code>*</code></label>
                                <InputText value={assignTo} disabled={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Forward To <code>*</code></label>
                                <InputText value={forwardTo} disabled={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comment</label>
                                <textarea disabled={true} value={comment} onChange={(e)=> setComment(e.target.value)} name="comment" id="comment" rows="5" className={'form-control'}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <Edit/>&nbsp;&nbsp;&nbsp;
                        <Del/>&nbsp;&nbsp;&nbsp;
                    </div>
                </Dialog>
                <ConfirmPopup/>
                <DataTable value={customerData} loading={loading} emptyMessage={'No Follow Up Found !'} paginator
                           rows={10}
                           onRowClick={async (p) => {
                               let id = customerData[p.index].id;
                               setLeadNo(customerData[p.index].LeadNo);
                               setParentType(customerData[p.index].ParentType);
                               setCallResponse(customerData[p.index].CallResponse);
                               setPriority(customerData[p.index].Priority);
                               setType(customerData[p.index].Type);
                               setDepartment(customerData[p.index].Department);
                               setDueDate(customerData[p.index].DueDate);
                               setRelated(customerData[p.index].Related);
                               setAssignTo(customerData[p.index].AssignTo);
                               setComment(customerData[p.index].Comment);
                               setForwardTo(customerData[p.index].ForwardTo);
                               setUpdateId(id);
                               setVisible(true);
                           }}
                           rowClassName={'cursor-pointer'}
                           rowsPerPageOptions={[5, 10, 25, 50]}
                           tableStyle={{minWidth: '50rem', overflow: "auto"}}
                           expandedRows={expandedRows} onRowToggle={(e) => {
                    setExpandedRows(e.data);
                }}
                           rowExpansionTemplate={rowExpansionTemplate}
                >
                    <Column expander={true} style={{width: '5rem'}}/>
                    <Column field="LeadNo" header="File No" style={{minWidth: '10rem'}}/>
                    <Column field="Name" header="Name" style={{minWidth: '10rem'}}/>
                    <Column field="Father Name" header="Father Name" style={{minWidth: '10rem'}}/>
                    <Column field="Phone" header="Phone No" style={{minWidth: '10rem'}}/>
                    <Column field="AssignTo" header="AssignTo" style={{minWidth: '10rem'}}/>
                    <Column field="ForwardTo" header="ForwardTo" style={{minWidth: '8rem'}}/>
                </DataTable>
            </Card>
        </section>
    );
}
