import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import {Dropdown} from "primereact/dropdown";
import {useHistory} from "react-router-dom";

export default function AddGTE() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [updateId, setUpdateId] = useState('');
    const [date, setDate] = useState('');
    const [leadNo, setLeadNo] = useState('');
    const [leadNoData, setLeadNoData] = useState([]);

    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);
        LoadLeadNo();
        if (res.id) {
            setUpdateId(res.id);
            LoadFollowDetail(res.id);
        }

    }, []);

    async function LoadFollowDetail(id) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadGTE");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    Data = Data[0];
                    setLeadNo(Data.LeadNo)
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        formdata.append("CreatedBy", localStorage.getItem("UserId"));
        formdata.append("LeadNo", leadNo);
        if (updateId) {
            formdata.append("page", "UpdateGTE");
            formdata.append("id", updateId);
        } else {
            formdata.append("page", "SaveGTE");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    // let Data = result.Data;
                    $('#Form').trigger('reset');
                    setLeadNo("");
                    showSuccess(updateId ? "Record Updated Successfully" : "Record Saved Successfully");
                    setUpdateId("");
                    LoadLeadNo();
                    setTimeout(()=>{
                        navigate.go(-1)
                    },1000)
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    async function LoadLeadNo() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadLeadNoData");
        formdata.append("role", localStorage.getItem("Role"));
        formdata.append("user", localStorage.getItem("UserId"));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}Leads.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    setLeadNoData(result.data);
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={() => navigate.go(-1)}
                                    style={{border: "none", outline: "none", background: "transparent"}}><i
                                className={'fas fa-arrow-left'}></i></button>
                            &nbsp;&nbsp;&nbsp;
                            <span>Add GTE</span>
                        </>
                    )
                }}
                className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>File No <code>*</code></label>
                                <Dropdown filter={true} name={'leadNo'} value={leadNo} onChange={(e) => setLeadNo(e.value)}
                                          options={leadNoData} optionLabel="name"
                                          placeholder="Select File No" className='form-control'/>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 1 <code>*</code></label>
                                    <InputText name='doc1'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload1' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 2 <code>*</code></label>
                                    <InputText name='doc2'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload2' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 3 <code>*</code></label>
                                    <InputText name='doc3'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload3' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 4 <code>*</code></label>
                                    <InputText name='doc4'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload4' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 5 <code>*</code></label>
                                    <InputText name='doc5'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload5' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 6 <code>*</code></label>
                                    <InputText name='doc6'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload6' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 7 <code>*</code></label>
                                    <InputText name='doc7'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload7' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 8 <code>*</code></label>
                                    <InputText name='doc8'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload8' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 9 <code>*</code></label>
                                    <InputText name='doc9'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload9' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 10 <code>*</code></label>
                                    <InputText name='doc10'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload10' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 11 <code>*</code></label>
                                    <InputText name='doc11'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload11' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 12 <code>*</code></label>
                                    <InputText name='doc12'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload12' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 13 <code>*</code></label>
                                    <InputText name='doc13'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload13' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 14 <code>*</code></label>
                                    <InputText name='doc14'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload14' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document 15 <code>*</code></label>
                                    <InputText name='doc15'
                                               placeholder={'Enter Enter Document Title'}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Document <code>*</code></label>
                                    <InputText name='upload15' type={'file'} className={'form-control'}/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-3">
                        {
                            updateId ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
