import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Card} from 'primereact/card';
import {Toast} from "primereact/toast";
import $ from 'jquery';
import AppLink from "../components/Link";
import {useHistory} from "react-router-dom";

export default function AddUser() {
    const navigate = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [leadData, setLeadData] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [branch, setBranch] = useState('');
    useEffect(() => {
        function getQueryParams(qs) {
            qs = qs.split('+').join(' ');

            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;

            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
            }

            return params;
        }

        let res = getQueryParams(window.location.search);
        if (res) {
            LoadLeadDetail(res.id);
        }
    }, []);

    async function LoadLeadDetail(id) {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formdata = new FormData();
        formdata.append("page", "LoadUser");
        formdata.append("id", id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}users.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status == "1") {
                    let Data = result.data;
                    setLeadData(Data);
                    Data = Data[0];
                    setName(Data.UserName);
                    setEmail(Data.Email);
                    setPassword(Data.Password);
                    setRole(Data.Role);
                    setBranch(Data.Branch)
                    setLoading(false);
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    const showSuccess = (msg) => {
        toast.current.show({severity: 'success', summary: 'Success', detail: msg, life: 3000});
    }
    const showError = () => {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Server Error', life: 3000});
    }

    const submitHandle = async (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=naap00olkgf00pm7uiusbbf9gb");
        var formEl = document.forms.Form;
        var formdata = new FormData(formEl);
        if (leadData.length > 0) {
            formdata.append("page", "UpdateUser");
            formdata.append("id", leadData[0].id);
        } else {
            formdata.append("page", "SaveUser");
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        await fetch(`${AppLink}users.php`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                if (result.status == "1") {
                    $('#Form').trigger('reset');
                    setName("");
                    setEmail("");
                    setPassword("");
                    setRole("");
                    setBranch("");
                    showSuccess(leadData.length > 0 ? "Record Updated Successfully" : "Record Saved Successfully");
                    setLeadData([]);
                    // setCustomerData(Data);
                    setTimeout(()=>{
                        navigate.go(-1)
                    },1000)
                } else {
                    showError();
                    console.log("Server Error. Please Try Again Later.(Code : 403)");
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <section className="content">
            <Card
                title={() => {
                    return (
                        <>
                            <button onClick={()=> navigate.go(-1) } style={{ border : "none",outline : "none",background : "transparent" }}><i className={'fas fa-arrow-left'}></i></button>&nbsp;&nbsp;&nbsp;
                            <span>Add User</span>
                        </>
                    )
                }}
                className="card">
                <Toast ref={toast}/>
                <form id={'Form'} onSubmit={submitHandle}>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="Name">User Name <code>*</code></label>
                            <InputText id="Name" value={name} onChange={(e) => setName(e.target.value)} name='name'
                                       placeholder={'Enter User Name'} autoFocus={true} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Email Address <code>*</code></label>
                            <InputText id="Email" value={email} onChange={(e) => setEmail(e.target.value)} name='email'
                                       placeholder={'Enter Email Address'} required={true}/>
                        </div>
                        <div className="col-md-6">
                            <label>Password <code>*</code></label>
                            <InputText id="Password" value={password} onChange={(e) => setPassword(e.target.value)} name='password'
                                       placeholder={'Enter Password'} type={'password'} required={true}  />
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                            <label>User Role <code>*</code></label>
                            <Dropdown name={'role'} value={role} onChange={(e) => setRole(e.value)} options={[
                                {
                                    name : "Operator",
                                    value : "Operator"
                                },
                                {
                                    name : "Manager",
                                    value : "Manager"
                                },
                                {
                                    name : "Counselor",
                                    value : "Counselor"
                                }
                            ]} optionLabel="name"
                                      placeholder="Select a Role" className='form-control' />
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="form-group">
                                <label>Branch <code>*</code></label>
                                <Dropdown name={'branch'} value={branch} onChange={(e) => setBranch(e.value)} options={[
                                    {
                                        name : "Multan",
                                        value : "Multan"
                                    },
                                    {
                                        name : "Gujranwala",
                                        value : "Gujranwala"
                                    },
                                    {
                                        name : "Sahiwal",
                                        value : "Sahiwal"
                                    },
                                    {
                                        name : "Bahawalpur",
                                        value : "Bahawalpur"
                                    }
                                ]} optionLabel="name"
                                          placeholder="Select a Branch" className='form-control' />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {
                            leadData.length > 0 ?
                                <Button label={loading ? "Updating..." : "Update Record"} severity={'help'}
                                        disabled={loading}/> :
                                <Button label={loading ? "Saving..." : "Save Record"} disabled={loading}/>
                        }
                        {/*<Button  label={loading ? "Saving..." : "Save Record"} disabled={loading} />*/}
                    </div>
                </form>
            </Card>
        </section>

    );
}
